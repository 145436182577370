import React, { useEffect, useRef, useState } from "react";

import styled, { css, keyframes } from "styled-components";
import { media } from "../../../components/media";

interface Props {
  text: string;
}

export default function TextAnimation(props: Props) {
  const [displayText, setDisplayText] = React.useState<string>("");
  const [i, setI] = useState(0);

  const normalTextTime = 20;
  const specialTextTime = 60;

  useEffect(() => {
    setI(0);
    setDisplayText("");
  }, [props.text]);

  useEffect(() => {
    const interval = setInterval(
      () => {
        if (i >= props.text.length) {
          clearInterval(interval);
        } else {
          setDisplayText((prev) => {
            return prev + props.text[i];
          });

          setI((prev) => prev + 1);
        }
      },
      props.text[i] === " " ? specialTextTime : normalTextTime
    );

    // 클린업 함수
    return () => clearInterval(interval);
  }, [i]); // props.text 의존성 추가

  return (
    <Container>
      <div
        className="inter fs-24 fw-400 phablet:fs-16 tablet:fs-20"
        style={{ color: "white" }}
      >
        {displayText}
      </div>
      <Carat $animation={displayText.length === props.text.length} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
`;

const blinking = keyframes`
    0% {
        opacity: 0;
    }
    35% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
    `;

const Carat = styled.div.attrs<{ $animation: boolean }>((props) => ({}))`
  width: 3px;
  height: 40px;
  background: white;
  animation: ${(props) =>
    props.$animation
      ? css`
          ${blinking} 1s linear infinite
        `
      : "none"};

  ${media.tablet`
      display : none;`}
`;
