import * as THREE from "three";
import { SlabopBase } from "./SlabopBase"; // SlabopBase가 정의된 파일에서 import합니다.
import { Slab } from "../Slab";

export class VorticityConfinement extends SlabopBase {
  public grid: any; // grid 타입을 적절하게 정의해야 합니다.
  public time: any; // time 타입을 적절하게 정의해야 합니다.
  public epsilon: number;
  public curl: number;

  constructor(
    fs: string,
    grid: any,
    time: any,
    epsilon?: number,
    curl?: number
  ) {
    const uniforms = {
      velocity: { type: "t" },
      vorticity: { type: "t" },
      gridSize: { type: "v2" },
      gridScale: { type: "f" },
      timestep: { type: "f" },
      epsilon: { type: "f" },
      curl: { type: "v2", value: new THREE.Vector2() },
    };

    super(fs, uniforms, grid); // SlabopBase를 호출하여 초기화

    this.grid = grid;
    this.time = time;
    this.epsilon = epsilon ?? 2.4414e-4;
    this.curl = curl ?? 0.3;
  }

  public compute(
    renderer: THREE.WebGLRenderer,
    velocity: Slab,
    vorticity: Slab,
    output: Slab
  ): void {
    this.uniforms.velocity.value = velocity.read.texture;
    this.uniforms.vorticity.value = vorticity.read.texture;
    this.uniforms.gridSize.value = this.grid.size;
    this.uniforms.gridScale.value = this.grid.scale;
    this.uniforms.timestep.value = this.time.step;
    this.uniforms.epsilon.value = this.epsilon;

    // curl 값을 설정
    this.uniforms.curl.value.set(
      this.curl * this.grid.scale,
      this.curl * this.grid.scale
    );

    // output.write를 renderTarget으로 설정하여 렌더링
    renderer.setRenderTarget(output.write);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null); // 기본 render target으로 되돌리기

    output.swap();
  }
}
