import React from "react";
import styled from "styled-components";
import { ReactComponent as EndolphinLogo } from "../../../assets/images/endolphin_logo.svg";
import LogoAnimationComponent from "./LogoAnimationComponent";
import { media } from "../../../components/media";

export default function PartnerSection() {
  return (
    <Container>
      <TopContainer>
        <div className="inter fs-24 fw-600 phablet:fs-16">Partner Stories</div>
      </TopContainer>
      <BodyContainer>
        <LeftContainer>
          <LogoContainer>
            <EndolphinLogo style={{ width: "100%" }} />
            <div
              className="inter fs-16 fw-400"
              style={{
                color: "white",
                marginTop: -10,
              }}
            >
              Webtoon studio in Tokyo
            </div>
          </LogoContainer>
        </LeftContainer>
        <RightContainer>
          <TitleContainer>
            <div
              className="inter fs-48 fw-700 phablet:fs-28"
              style={{ color: "white" }}
            >
              “ Operational efficiency has surged to an impressive ten times
              greater than previous methods! ”
            </div>
            <div
              className="inter fs-18 fw-400 phablet:fs-12"
              style={{ color: "white", width: "100%" }}
            >
              安天 | Localizing Leader, en-dolphin
            </div>
          </TitleContainer>
          <ContentsContainer>
            <div
              className="inter fs-18 fw-300 phablet:fs-14 lh-1.9 phone:lh-1.6"
              style={{ color: "white" }}
            >
              At the webtoon studio "En-dolphin," we have successfully
              integrated "reat" into our daily operations. Since implementing
              reat, we’ve seen our operational efficiency improve tenfold
              compared to our previous methods. Communication discrepancies have
              been greatly reduced, and the time spent on feedback has decreased
              by over half. <br />
              We’re thrilled to share this positive experience, which has led us
              to expand our usage of reat far beyond our initial expectations.
            </div>
          </ContentsContainer>
        </RightContainer>
      </BodyContainer>
      <div style={{ height: 30 }} />
      <PartnerLogoContainer>
        <LogoAnimationComponent />
      </PartnerLogoContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  overflow: hidden;
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;

  padding-top: 480px;
  ${media.tablet`
    padding-left: 20px;
    `}

  ${media.phablet`
    padding-top : 0px;`}
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;

  padding-top: 100px;

  justify-content: space-between;

  ${media.tablet`
    flex-direction: column;
    padding-top : 40px;
    `}
`;

const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-image: url(${require("../../../assets/images/partner-background.png")});
  background-size: cover;
  width: 476px;
  min-width: 476px;
  max-width: 476px;
  min-height: 713px;

  align-items: center;
  justify-content: center;

  ${media.tablet`
    width: 100%;
    min-width: 100%;
    max-width: 100%;
    height : 490px;
    min-height : unset;
    background-size : cover;
    `}
`;

const LogoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${media.phablet`
    width : 300px;
    `}
`;

const RightContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  flex-direction: column;
  background-color: black;
  padding: 80px 50px 80px 50px;
  box-sizing: border-box;
  justify-content: space-between;
  gap: 80px;

  ${media.phablet`
    gap: 40px;
    padding : 60px 20px;
    `}
`;

const TitleContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  gap: 40px;

  ${media.phablet`
    gap : 20px;
    align-items : flex-start;
    `}
`;

const PartnerLogoContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: center;
`;

const ContentsContainer = styled.div`
  display: flex;
  width: 100%;
`;
