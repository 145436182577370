import * as THREE from "three";
import { Slab } from "./Slab";

export class Display {
  public bias: THREE.Vector3;
  public scale: THREE.Vector3;
  public uniforms: any;
  private readonly material: THREE.ShaderMaterial;
  private readonly camera: THREE.OrthographicCamera;
  private readonly scene: THREE.Scene;

  constructor(
    vs: string,
    fs: string,
    bias?: THREE.Vector3,
    scale?: THREE.Vector3
  ) {
    this.bias = bias ?? new THREE.Vector3(0, 0, 0);
    this.scale = scale ?? new THREE.Vector3(1, 1, 1);

    this.uniforms = {
      read: { type: "t" },
      bias: { type: "v3" },
      scale: { type: "v3" },
    };

    this.material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      vertexShader: vs,
      fragmentShader: fs,
      depthWrite: false,
      depthTest: false,
      blending: THREE.NoBlending,
    });

    const quad = new THREE.Mesh(new THREE.PlaneGeometry(2, 2), this.material);
    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    this.scene = new THREE.Scene();
    this.scene.add(quad);
  }

  // set bias and scale for including range of negative values
  public scaleNegative(): void {
    const v = 0.5;
    this.bias.set(v, v, v);
    this.scale.set(v, v, v);
  }

  public render(
    renderer: THREE.WebGLRenderer,
    read: THREE.WebGLRenderTarget
  ): void {
    this.uniforms.read.value = read.texture;
    this.uniforms.bias.value = this.bias;
    this.uniforms.scale.value = this.scale;

    renderer.render(this.scene, this.camera);
  }
}
