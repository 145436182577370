import * as THREE from "three";
import { SlabopBase } from "./SlabopBase";
import { Slab } from "../Slab";

export class Advect extends SlabopBase {
  public grid: any; // grid 타입을 적절하게 정의해야 합니다.
  public time: any; // time 타입을 적절하게 정의해야 합니다.
  public dissipation: number;

  constructor(fs: string, grid: any, time: any, dissipation?: number) {
    super(
      fs,
      {
        velocity: { type: "t" },
        advected: { type: "t" },
        gridSize: { type: "v2" },
        gridScale: { type: "f" },
        timestep: { type: "f" },
        dissipation: { type: "f" },
      },
      grid
    );

    this.grid = grid;
    this.time = time;
    this.dissipation = dissipation ?? 0.998;
  }

  public compute(
    renderer: THREE.WebGLRenderer,
    velocity: Slab,
    advected: Slab,
    output: Slab
  ): void {
    this.uniforms.velocity.value = velocity.read.texture;
    this.uniforms.advected.value = advected.read.texture;
    this.uniforms.gridSize.value = this.grid.size;
    this.uniforms.gridScale.value = this.grid.scale;
    this.uniforms.timestep.value = this.time.step;
    this.uniforms.dissipation.value = this.dissipation;

    renderer.setRenderTarget(output.write);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null);
    output.swap();
  }
}
