import React, { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as TypoText } from "../../../../assets/images/storyboarding_tool_typo.svg";
import { ReactComponent as ToondaNotebook } from "../../../../assets/images/toonda_notebook.svg";
import { ReactComponent as ToondaLogo } from "../../../../assets/icons/toondaMainLogo.svg";
import SquareButton from "../../../../components/SquareButton";
import { media } from "../../../../components/media";

interface Props {
  width: number;
}
export default function Toodna(props: Props) {
  const eventRef = React.useRef<HTMLDivElement>(null);
  const animateRef = React.useRef<HTMLDivElement>(null);
  const [eventHappened, setEventHappened] = React.useState(false);

  useEffect(() => {
    // use intersection observer to trigger animation
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          if (entry.isIntersecting) {
            setEventHappened(true);
          }
        },
        { threshold: 1.0 }
      );
    });

    if (eventRef.current) {
      observer.observe(eventRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Container id="toonda">
      <TopContainer ref={animateRef} $visible={eventHappened}>
        <div className="inter fs-36 fw-500 phone:fs-18 tablet:fs-24">
          Shape Ideas Swiftly
        </div>
        <div
          className="briem fs-96 fw-800 phone:fs-36 phablet:fs-48 tablet:fs-60"
          style={{
            whiteSpace: "nowrap",
            letterSpacing: "-0.05em",
            marginTop: props.width < 800 ? "-0.4em" : "-0.3em",
          }}
        >
          Storyboarding Tool
        </div>
      </TopContainer>
      <ImageContainer ref={eventRef}>
        <img
          src={require("../../../../assets/images/toonda_notebook.png")}
          alt=""
          width="100%"
        />
      </ImageContainer>
      <BodyContainer>
        <ContentContainer>
          <LogoContainer>
            <ToondaLogo style={{ width: "100%", height: "100%" }} />
          </LogoContainer>
          <div className="inter fs-18 fw-300 phablet:fs-14 lh-1.6">
            TOONDA is a storyboarding solution crafted to streamline the
            creation of visual narratives, such as in webtoons, animation, and
            film. Its primary aim is to make the storyboarding process faster,
            more intuitive, and accessible, allowing creators to quickly bring
            initial ideas to visual life without unnecessary barriers. TOONDA
            focuses on helping creators simplify complex visual storytelling,
            from brainstorming to finished sequences, making it ideal for
            story-driven industries where speed and precision are essential.
            This tool reflects CONPAPA’s vision of empowering the creative
            process at the foundational stage, when ideas are first mapped out.
          </div>
        </ContentContainer>
        <SquareButton
          text="Learn More"
          bgcolor="#000000"
          textcolor="#FFFFFF"
          fontSize={24}
          mobileFontSize={18}
          onClick={() => {
            window.open("https://toonda.com/", "_blank");
          }}
        />
      </BodyContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const up = keyframes`
    from {
        opacity: 0;
        transform: translateY(300px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    `;

const phablet_up = keyframes`
    from {
        opacity: 0;
        transform: translateY(300px);
    }
    60% {
        opacity : 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const TopContainer = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: 0;

  animation: ${(props) =>
    props.$visible
      ? css`
          ${up} 1.0s ease-in-out forwards
        `
      : "none"};

  ${media.phablet`
  animation: ${(props: any) =>
    props.$visible
      ? css`
          ${phablet_up} 0.7s ease-in-out forwards
        `
      : "none"};
        `}
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 5;
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding: 80px;
  gap: 48px;

  ${media.phablet`
    padding: 60px 20px 80px 20px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 220px;
  ${media.phablet`
    width : 160px;`}
`;
