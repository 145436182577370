import { render } from "@testing-library/react";
import React, { useEffect, useRef } from "react";
import * as THREE from "three";
import { ThreeSceneRes } from "../../types";
import { F2D } from "./f2d";
import { Solver } from "./f2d/Solver";
import { Display } from "./f2d/Display";
import styled from "styled-components";

const THREE_WIDTH = 1024;
const THREE_HEIGHT = 800;
export default function ThreeScene2() {
  const containerRef = useRef<HTMLDivElement | null>(null);

  const rendererRef = useRef<THREE.WebGLRenderer | null>(null);
  const grid = {
    size: new THREE.Vector2(
      THREE_WIDTH,
      (THREE_WIDTH * THREE_HEIGHT) / window.innerWidth
    ),
    scale: 1,
    ratio: THREE_WIDTH / window.innerWidth,
    applyBoundaries: false,
  };
  const time = {
    step: 5,
  };

  const solverRef = useRef<Solver | null>(null);
  const displayScalarRef = useRef<Display | null>(null);
  const displayVectorRef = useRef<Display | null>(null);

  const mouse = new F2D.Mouse(grid);

  function init(shaders: { [key: string]: string }) {
    solverRef.current = F2D.Solver.make(grid, time, grid.size, shaders);

    displayScalarRef.current = new F2D.Display(
      shaders.basic,
      shaders.displayscalar
    );
    displayVectorRef.current = new F2D.Display(
      shaders.basic,
      shaders.displayvector
    );

    requestAnimationFrame(update);
  }

  function update() {
    if (!rendererRef.current) return;

    solverRef.current!.step(rendererRef.current, mouse);
    render();

    requestAnimationFrame(update);
  }

  function render() {
    if (rendererRef.current == null) return;

    displayScalarRef.current!.scale.copy(solverRef.current!.ink);
    displayScalarRef.current!.bias.set(0, 0, 0);
    const read: THREE.WebGLRenderTarget = solverRef.current!.density.read;

    displayScalarRef.current!.render(rendererRef.current, read);
  }

  useEffect(() => {
    const containerCurrent = containerRef.current;

    // init renderer
    rendererRef.current = new THREE.WebGLRenderer({
      antialias: true,
      alpha: true,
    });
    rendererRef.current.autoClear = false;
    rendererRef.current.sortObjects = false;
    rendererRef.current.setPixelRatio(window.devicePixelRatio);
    rendererRef.current.setSize(window.innerWidth, THREE_HEIGHT);

    if (containerCurrent)
      containerCurrent.appendChild(rendererRef.current.domElement);

    // shader load
    const loader = new F2D.FileLoader("shaders", [
      "advect.fs",
      "basic.vs",
      "gradient.fs",
      "jacobiscalar.fs",
      "jacobivector.fs",
      "displayscalar.fs",
      "displayvector.fs",
      "divergence.fs",
      "splat.fs",
      "vorticity.fs",
      "vorticityforce.fs",
      "boundary.fs",
    ]);

    loader.run(function (files) {
      let shaders: { [key: string]: string } = {};
      for (let name in files) {
        shaders[name.split(".")[0]] = files[name];
      }

      init(shaders);
    });

    return () => {
      // clean up
      if (rendererRef.current) {
        rendererRef.current?.dispose();

        if (containerCurrent)
          containerCurrent.removeChild(rendererRef.current.domElement);
      }

      rendererRef.current = null;
    };
  }, []);

  function resize() {
    rendererRef.current?.setSize(window.innerWidth, THREE_HEIGHT);
  }
  window.onresize = resize;

  return (
    <div
      ref={containerRef}
      style={{
        position: "relative",
        width: "100vw",
        height: THREE_HEIGHT,
      }}
    >
      <div
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          userSelect: "none",
          pointerEvents: "none",
        }}
      >
        <div
          className="inter fs-36 phablet:fs-24 fw-300"
          style={{
            textAlign: "center",
          }}
        >
          A{" "}
          <b>
            <i>
              <span>Formless</span>
            </i>
          </b>{" "}
          Idea
        </div>
        <div className="inter fs-36 fw-300 phablet:fs-24">
          goes through <span className="shrik">Forming</span>
        </div>
      </div>
    </div>
  );
}
