import React from "react";
import styled from "styled-components";
import { Check } from "../../../../assets/icons/Check";
import SquareButton from "../../../../components/SquareButton";
import { media } from "../../../../components/media";
import axios from "axios";

export default function SubmitFormSection() {
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [workEmail, setWorkEmail] = React.useState("");
  const [company, setCompany] = React.useState("");
  const [jobTitle, setJobTitle] = React.useState("");
  const [country, setCountry] = React.useState("");
  const [message, setMessage] = React.useState("");
  const [checked, setChecked] = React.useState(false);

  async function handleSendInquiry() {
    try {
      await axios.post(
        "https://vwxzh1e47b.execute-api.ap-northeast-2.amazonaws.com/default/contact/organization",
        {
          firstName,
          lastName,
          phoneNumber,
          email: workEmail,
          company,
          jobTitle,
          country,
          message,
        }
      );

      alert("Inquiry sent successfully!");

      // Clear form
      setFirstName("");
      setLastName("");
      setPhoneNumber("");
      setWorkEmail("");
      setCompany("");
      setJobTitle("");
      setCountry("");
      setMessage("");
      setChecked(false);
    } catch (e) {
      console.error(e);
      alert("Failed to send inquiry. Please try again later.");
    }
  }
  return (
    <Container id="business">
      <BodyContainer>
        <RowContainer>
          <InputContainer
            placeholder="First Name *"
            value={firstName}
            onChange={(e) => setFirstName(e.target.value)}
          />
          <InputContainer
            placeholder="Last Name *"
            value={lastName}
            onChange={(e) => setLastName(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <InputContainer
            placeholder="Phone number"
            value={phoneNumber}
            onChange={(e) => setPhoneNumber(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <InputContainer
            placeholder="Email *"
            value={workEmail}
            onChange={(e) => setWorkEmail(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <InputContainer
            placeholder="Company *"
            value={company}
            onChange={(e) => setCompany(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <InputContainer
            placeholder="Job title *"
            value={jobTitle}
            onChange={(e) => setJobTitle(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <InputContainer
            placeholder="Country *"
            value={country}
            onChange={(e) => setCountry(e.target.value)}
          />
        </RowContainer>
        <RowContainer>
          <TextAreaContainer
            placeholder="Message *"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </RowContainer>

        <RowContainer
          style={{ cursor: "pointer" }}
          onClick={() => setChecked((prev) => !prev)}
        >
          <div
            style={{
              display: "flex",
              gap: 8,
              alignItems: "center",
            }}
          >
            <CheckContainer>
              <Check checked={checked} />
            </CheckContainer>
            <div
              className="inter fs-12 fw-500 phablet:fs-10"
              style={{ marginTop: 2 }}
            >
              I acknowledge that the information I submit through this form is
              subject to CONPAPA's governance
            </div>
          </div>
        </RowContainer>
      </BodyContainer>
      <SquareButton
        text="Submit"
        bgcolor="black"
        textcolor="white"
        fontSize={36}
        mobileFontSize={18}
        disabled={
          firstName === "" ||
          lastName === "" ||
          workEmail === "" ||
          company === "" ||
          jobTitle === "" ||
          country === "" ||
          message === "" ||
          !checked
        }
        onClick={handleSendInquiry}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  max-width: 720px;
  width: 100%;
  padding: 80px;
  background-image: url(${require("../../../../assets/images/inquiry-form-background.png")});
  background-size: cover;

  gap: 40px;

  ${media.phablet`
    padding : 80px 20px;
    `}
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  gap: 24px;
`;

const RowContainer = styled.div`
  display: flex;
  gap: 24px;

  ${media.phablet`
    flex-direction : column;
    gap : 24px;
    `}
`;

const InputContainer = styled.input`
  background: rgba(0, 0, 0, 0.6);
  flex: 1;
  height: 54px;
  color: white;
  outline: 1px solid black;
  border: none;
  font-family: "Inter", "Noto Sans KR", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 48px;
  letter-spacing: -0.03em;
  padding: 20px 20px;
  box-sizing: border-box;

  &::placeholder {
    color: #bbbbbb;
  }

  ${media.phablet`
    font-size : 14px;
    padding : 10px 20px;
    flex : unset;
    `}
`;

const TextAreaContainer = styled.textarea`
  background: rgba(0, 0, 0, 0.6);
  flex: 1;
  color: white;
  height: 240px;
  outline: 1px solid black;
  border: none;
  font-family: "Inter", "Noto Sans KR", sans-serif;
  font-style: normal;
  font-size: 16px;
  line-height: 19.36px;
  letter-spacing: -0.03em;
  padding: 20px 20px;
  box-sizing: border-box;

  // no resize
  resize: none;

  &::placeholder {
    color: #bbbbbb;
  }

  // change scroll
  &::-webkit-scrollbar {
    width: 10px;
  }

  &::-webkit-scrollbar-thumb {
    background: #888;
  }

  ${media.phablet`
    font-size : 14px;
    padding : 10px 20px;
    flex : unset;
    `}
`;

const CheckContainer = styled.div`
  width: 16px;
  height: 16px;
`;
