import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

interface Props {
  text: string;
  width: number;
  bold?: boolean;
  onClick?: () => void;
}

export default function HeaderButton(props: Props) {
  const location = useLocation();

  const [isBold, setIsBold] = React.useState(false);
  const [isHover, setIsHover] = React.useState(false);
  useEffect(() => {
    if (location.pathname.toLowerCase().includes(props.text.toLowerCase()))
      setIsBold(true);
    else setIsBold(false);
  }, [location, props.text]);

  return (
    <Container
      $width={props.width}
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      onClick={props.onClick}
    >
      <div className={`inter fs-16 fw-${isBold || isHover ? 800 : 400}`}>
        {props.text}
      </div>
    </Container>
  );
}

const Container = styled.div.attrs<{ $width: number }>((props) => ({
  style: {
    width: `${props.$width}px`,
  },
}))`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  transition: font-weight 0.3s;
`;
