import * as THREE from "three";
import { SlabopBase } from "./SlabopBase"; // SlabopBase가 정의된 파일에서 import합니다.
import { Slab } from "../Slab";
import { Boundary } from "./Boundary";

export class Jacobi extends SlabopBase {
  public grid: any; // grid 타입을 적절하게 정의해야 합니다.
  public iterations: number;
  public alpha: number;
  public beta: number;

  constructor(
    fs: string,
    grid: any,
    iterations?: number,
    alpha?: number,
    beta?: number
  ) {
    super(
      fs,
      {
        x: { type: "t" },
        b: { type: "t" },
        gridSize: { type: "v2" },
        alpha: { type: "f" },
        beta: { type: "f" },
      },
      grid
    );

    this.grid = grid;
    this.iterations = iterations ?? 50;
    this.alpha = alpha ?? -1;
    this.beta = beta ?? 4;
  }

  public compute(
    renderer: THREE.WebGLRenderer,
    x: Slab,
    b: Slab,
    output: Slab,
    boundary: Boundary,
    scale: number
  ): void {
    for (let i = 0; i < this.iterations; i++) {
      this.step(renderer, x, b, output);
      boundary.compute(renderer, output, scale, output);
    }
  }

  private step(
    renderer: THREE.WebGLRenderer,
    x: Slab,
    b: Slab,
    output: Slab
  ): void {
    this.uniforms.x.value = x.read.texture;
    this.uniforms.b.value = b.read.texture;
    this.uniforms.gridSize.value = this.grid.size;
    this.uniforms.alpha.value = this.alpha;
    this.uniforms.beta.value = this.beta;

    // output.write를 renderTarget으로 설정하여 렌더링
    renderer.setRenderTarget(output.write);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null); // 기본 render target으로 되돌리기

    output.swap();
  }
}
