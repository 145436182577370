import React, { useEffect } from "react";
import styled from "styled-components";
import PeopleSection from "./PeopleSection";
import { CareerSection } from "./CareerSection";
import PositionSection from "./PositionSection";
import { media } from "../../components/media";

export default function Company() {
  const [width, setWidth] = React.useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <Container>
      <BodyContainer id="our-culture">
        <div
          className="inter fs-76 fw-700 phone:fs-24 phablet:fs-36 tablet:fs-60"
          style={{ textAlign: "center" }}
        >
          Meet the Creative Minds
        </div>
        <HashtagContainer>
          <HashtagRowContainer>
            <div
              className="inter fs-24 fw-300 phablet:fs-14 tablet:fs-20"
              style={{ display: "flex" }}
            >
              #Uplifting Spirit
            </div>
            <div
              className="inter fs-24 fw-300 phablet:fs-14 tablet:fs-20"
              style={{ display: "flex" }}
            >
              #Open Leadership
            </div>
            <div
              className="inter fs-24 fw-300 phablet:fs-14 tablet:fs-20"
              style={{ display: "flex" }}
            >
              #Mutual Trust
            </div>
          </HashtagRowContainer>
          <HashtagRowContainer>
            <div
              className="inter fs-24 fw-300 phablet:fs-14 tablet:fs-20"
              style={{ display: "flex" }}
            >
              #Straightforward Comm.
            </div>
            <div
              className="inter fs-24 fw-300m phablet:fs-14 tablet:fs-20"
              style={{ display: "flex" }}
            >
              #Excellence
            </div>
          </HashtagRowContainer>
        </HashtagContainer>
      </BodyContainer>
      <HorizDividerSmall />
      <PeopleSection width={width} />
      <HorizDividerLarge />
      <CareerSection />
      <HorizDividerLarge />
      <PositionSection width={width} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 1440px;
  padding: 200px 80px;
  box-sizing: border-box;
  margin: 0 auto;
  overflow-x: hidden;

  flex-direction: column;

  ${media.phablet`
    padding : 80px 0px;
    max-width : 100%;
  `}
`;

const BodyContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 150px 0px;
  flex-direction: column;

  ${media.phablet`
    align-items : center;
    padding : 80px 0px;
    `}
`;

const HashtagContainer = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  padding: 60px 180px;
  box-sizing: border-box;
  gap: 24px;

  ${media.tablet`
    padding : 80px 60px;
    `}

  ${media.phablet`
    padding : 40px 0px;
    gap : 12px;
    max-width : 400px;
    min-width : 360px;
  `}
`;

const HashtagRowContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;
`;

const HorizDividerLarge = styled.div`
  height: 500px;

  ${media.phablet`
    height : 200px;`}
`;

const HorizDividerSmall = styled.div`
  height: 160px;

  ${media.phablet`
    height : 20px;`}
`;
