import styled from "styled-components";
import * as COLORS from "../../assets/colors";

export interface RegularTextProps {
  color?: string;
  selectable?: boolean;
  ellipsis?: boolean;
  hasUnderline?: boolean;
  numberOfLines?: number;
  maxWidth?: number;
  minWidth?: number;
}

export const RegularText = styled.span<RegularTextProps>`
  display: ${(props) => (props.numberOfLines == null ? "flex" : "-webkit-box")};
  ${(props) =>
    props.numberOfLines != null &&
    `-webkit-line-clamp: ${props.numberOfLines};
     -webkit-box-orient: vertical;
     overflow: hidden;
    `}

  ${(props) =>
    props.numberOfLines == null &&
    `
      white-space: ${props.ellipsis ? "nowrap" : "normal"};
      overflow: ${props.ellipsis ? "hidden" : "normal"};
      text-overflow: ${props.ellipsis ? "ellipsis" : "normal"};
    `};

  color: ${(props) => props.color || COLORS.NearBlack};
  user-select: ${(props) => (props.selectable ? "text" : "none")};
  font-style: normal;
  line-height: normal;
  text-decoration-line: ${(props) =>
    props.hasUnderline ? "underline" : "none"};
  ${(props) =>
    props.maxWidth && props.maxWidth > 0
      ? `max-width: ${props.maxWidth}px;`
      : props.maxWidth != null
      ? `max-width: 100px;`
      : ``};
  ${(props) => props.minWidth && `min-width: ${props.minWidth}px;`}
`;

export interface PretendardProps {
  size?: number;
  weight?: number;
  opacity?: number;
}

export const Pretendard = styled(RegularText)<PretendardProps>`
  font-family: Pretendard, sans-serif;
  ${(props) => (props.size ? `font-size: ${props.size}px;` : ``)}
  ${(props) => (props.weight ? `font-weight: ${props.weight};` : ``)}
  ${(props) => (props.opacity ? `opacity: ${props.opacity};` : ``)}
`;

export const P11_400 = styled(Pretendard)`
  font-size: 11px;
  font-weight: 400;
`;

export const P11_500 = styled(Pretendard)`
  font-size: 11px;
  font-weight: 500;
`;

export const P12_400 = styled(Pretendard)`
  font-size: 12px;
  font-weight: 400;
`;

export const P12_500 = styled(Pretendard)`
  font-size: 12px;
  font-weight: 500;
`;

export const P12_600 = styled(Pretendard)`
  font-size: 12px;
  font-weight: 600;
`;

export const P12_700 = styled(Pretendard)`
  font-size: 12px;
  font-weight: 700;
`;

export const P13_400 = styled(Pretendard)`
  font-size: 13px;
  font-weight: 400;
`;

export const P13_500 = styled(Pretendard)`
  font-size: 13px;
  font-weight: 500;
`;

export const P13_600 = styled(Pretendard)`
  font-size: 13px;
  font-weight: 600;
`;

export const P13_700 = styled(Pretendard)`
  font-size: 13px;
  font-weight: 700;
`;

export const P14_400 = styled(Pretendard)`
  font-size: 14px;
  font-weight: 400;
`;

export const P14_500 = styled(Pretendard)`
  font-size: 14px;
  font-weight: 500;
`;

export const P14_600 = styled(Pretendard)`
  font-size: 14px;
  font-weight: 600;
`;

export const P16_500 = styled(Pretendard)`
  font-size: 16px;
`;

export const P20 = styled(RegularText)`
  font-size: 20px;
`;

export const P16_600 = styled(Pretendard)`
  font-size: 16px;
  font-weight: 600;
`;

export const P20_500 = styled(Pretendard)`
  font-size: 20px;
  font-weight: 500;
`;

export const P24_600 = styled(Pretendard)`
  font-size: 24px;
  font-weight: 600;
`;

export const P32_600 = styled(Pretendard)`
  font-size: 32px;
  font-weight: 600;
`;
