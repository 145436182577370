import { BrowserRouter, Routes, Route } from "react-router-dom";

import Home from "./pages/Home";
import Header from "./components/Header";
import Footer from "./components/Footer";
import Contact from "./pages/Contact";
import Introduction from "./pages/Introduction";
import Product from "./pages/Product";
import OpenAlarm from "./pages/OpenAlarm";
import TopBar from "./components/TopBar";
import Company from "./pages/Company";

import { useEffect } from "react";

function App() {
  // 컴포넌트가 마운트되었을 때 body에 data-simplebar 속성을 추가
  useEffect(() => {
    document.body.setAttribute("data-simplebar", "true");

    // 컴포넌트 언마운트 시에는 data-simplebar 속성 제거
    return () => {
      document.body.removeAttribute("data-simplebar");
    };
  }, []);

  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/introduction" element={<Introduction />} />
        <Route path="/product" element={<Product />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/company" element={<Company />} />
        <Route path="/openalarm/" element={<OpenAlarm />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
