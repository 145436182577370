import * as THREE from "three";
import { SlabopBase } from "./SlabopBase"; // SlabopBase가 정의된 파일에서 import합니다.
import { Slab } from "../Slab";

export class Boundary {
  public grid: any; // grid 타입을 적절하게 정의해야 합니다.
  public uniforms: any;
  private scene: THREE.Scene;
  private camera: THREE.OrthographicCamera;
  private lineL: THREE.Line;
  private lineR: THREE.Line;
  private lineB: THREE.Line;
  private lineT: THREE.Line;
  private gridOffset: THREE.Vector3;

  constructor(fs: string, grid: any) {
    this.grid = grid;

    this.uniforms = {
      read: { type: "t" },
      gridSize: { type: "v2" },
      gridOffset: { type: "v2" },
      scale: { type: "f" },
    };

    const material = new THREE.ShaderMaterial({
      uniforms: this.uniforms,
      fragmentShader: fs,
      depthWrite: false,
      depthTest: false,
      blending: THREE.NoBlending,
    });

    const createLine = (positions: number[][]) => {
      const vertices = new Float32Array(positions.length * 3);
      for (let i = 0; i < positions.length; i++) {
        vertices[i * 3] = positions[i][0];
        vertices[i * 3 + 1] = positions[i][1];
        vertices[i * 3 + 2] = positions[i][2];
      }

      const geometry = new THREE.BufferGeometry();
      geometry.setAttribute("position", new THREE.BufferAttribute(vertices, 3));

      return new THREE.Line(geometry, material);
    };

    const ax = (this.grid.size.x - 2) / this.grid.size.x;
    const ay = (this.grid.size.y - 2) / this.grid.size.y;
    const bx = (this.grid.size.x - 1) / this.grid.size.x;
    const by = (this.grid.size.y - 1) / this.grid.size.y;

    this.lineL = createLine([
      [-ax, -ay, 0],
      [-bx, by, 0],
    ]);
    this.lineR = createLine([
      [ax, -ay, 0],
      [bx, by, 0],
    ]);
    this.lineB = createLine([
      [-ax, -ay, 0],
      [bx, -by, 0],
    ]);
    this.lineT = createLine([
      [-ax, ay, 0],
      [bx, by, 0],
    ]);

    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    this.scene = new THREE.Scene();

    this.gridOffset = new THREE.Vector3();
  }

  public compute(
    renderer: THREE.WebGLRenderer,
    input: Slab,
    scale: number,
    output: Slab
  ): void {
    if (!this.grid.applyBoundaries) return;

    this.uniforms.read.value = input.read.texture;
    this.uniforms.gridSize.value = this.grid.size;
    this.uniforms.scale.value = scale;

    this.renderLine(renderer, this.lineL, [1, 0], output);
    this.renderLine(renderer, this.lineR, [-1, 0], output);
    this.renderLine(renderer, this.lineB, [0, 1], output);
    this.renderLine(renderer, this.lineT, [0, -1], output);
  }

  private renderLine(
    renderer: THREE.WebGLRenderer,
    line: THREE.Line,
    offset: number[],
    output: Slab
  ): void {
    this.scene.add(line);
    this.gridOffset.set(offset[0], offset[1], 0.0);
    this.uniforms.gridOffset.value = this.gridOffset;
    renderer.setRenderTarget(output.write);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null);
    this.scene.remove(line);
    // output을 swap하지 않고, 다음 slab operation이 내부를 채우고 swap할 것입니다.
  }
}
