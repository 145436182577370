import React from "react";
import styled from "styled-components";
import { media } from "../../../components/media";

interface Props {
  width: number;
}

export default function PositionSection(props: Props) {
  return (
    <Container>
      <TitleContainer>
        <div className="inter fs-36 fw-700 phablet:fs-28 phone:fs-24">
          Open Positions
        </div>
      </TitleContainer>
      <BodyContainer>
        {props.width >= 800 ? (
          <TableContainer>
            <thead>
              <tr>
                <THContainer>
                  <div className="inter fs-14 fw-500">Job Title</div>
                </THContainer>
                <THContainer>
                  <div className="inter fs-14 fw-500">Department</div>
                </THContainer>
                <THContainer>
                  <div className="inter fs-14 fw-500">Location</div>
                </THContainer>
                <THContainer>
                  <div className="inter fs-14 fw-500">Type</div>
                </THContainer>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Sales Enablement Manager
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">Sales</div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Tokyo, Japan
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Full time
                  </div>
                </TDContainer>
              </tr>
              <tr>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Growth Marketing Manager
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Marketing
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Seoul, Korea
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Full time
                  </div>
                </TDContainer>
              </tr>
              <tr>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Videographer
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Creative
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Tokyo, Japan
                  </div>
                </TDContainer>
                <TDContainer>
                  <div className="inter fs-20 fw-500 tablet:fs-16">
                    Contract
                  </div>
                </TDContainer>
              </tr>
            </tbody>
          </TableContainer>
        ) : (
          <TableContainer>
            <thead>
              <TRContainer>
                <th style={{ paddingLeft: 10 }}>
                  <div
                    className="inter fs-14 fw-500"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Job title
                  </div>
                </th>
                <th style={{ border: "none" }} />
                <THContainer>
                  <div
                    className="inter fs-18 fw-500"
                    style={{ whiteSpace: "nowrap" }}
                  >
                    Sales Enablement Manager
                  </div>
                </THContainer>
              </TRContainer>
            </thead>
            <tbody>
              <TRContainer>
                <td style={{ border: "none" }} />
                <td style={{ border: "none" }} />
                <TDContainer>
                  <TextContainer>
                    <div
                      className="inter fs-18 fw-500"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Growth marketing Manager
                    </div>
                  </TextContainer>
                </TDContainer>
              </TRContainer>
              <TRContainer>
                <td style={{ border: "none" }} />
                <td style={{ border: "none" }} />
                <TDContainer>
                  <TextContainer>
                    <div
                      className="inter fs-18 fw-500"
                      style={{ whiteSpace: "nowrap" }}
                    >
                      Videographer
                    </div>
                  </TextContainer>
                </TDContainer>
              </TRContainer>
            </tbody>
          </TableContainer>
        )}
        <EmailContainer>
          <div className="inter fs-12 fw-400">
            * E-mail us to contact@conpapa.co
          </div>
        </EmailContainer>
      </BodyContainer>
      <HorizDivider />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  flex-direction: column;
  gap: 60px;

  ${media.phablet`
    gap : 40px;
    `}
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-end;
  gap: 20px;
`;

const TableContainer = styled.table`
  cell-spacing: 0;
  cell-padding: 0;
  border-collapse: collapse;
  border-top: 3px solid black;
  ${media.tablet`
    border-bottom : 1px solid black;`}
`;

const TitleContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-left: 20px;

  ${media.phablet`
    justify-content : flex-start;`}
`;

const THContainer = styled.th`
  text-align: left;
  border-top: 1px solid black;
  padding: 10px 0px 10px 70px;
  border-bottom: 1px solid black;

  ${media.tablet`
    padding: 10px 0px 10px 20px;
    border-bottom : none;
    width : fit-content;
    `}
`;

const TDContainer = styled.td`
  border-bottom: 1px solid black;
  padding: 10px 0px 10px 70px;

  ${media.tablet`
    padding: 10px 0px 10px 20px;
    border-top : 1px solid black;
    `}
`;

const TRContainer = styled.tr`
  :nth-child(1) {
    width: 10%;
  }

  :nth-child(2) {
    ${media.phablet`
      width : 10%;`}
  }

  :nth-child(3) {
    ${media.phablet`
      width : 80%;`}
  }
`;

const EmailContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;

  box-sizing: border-box;
  padding: 0px 16px 0px 0px;

  ${media.phablet`
    padding-right: 20px;`}
`;

const TextContainer = styled.div`
  display: flex;
`;

const HorizDivider = styled.div`
  height: 320px;

  ${media.phablet`
    height : 160px;`}
`;
