import React from "react";
import styled from "styled-components";
import { media } from "../../../components/media";
import { ReactComponent as RightSmallArrow } from "../../../assets/icons/page-arrow-right-small.svg";
import { ReactComponent as LeftSmallArrow } from "../../../assets/icons/page-arrow-left-small.svg";

interface Props {
  name: string;
  role: string;
  bannerColor: string;
  fontColor?: string;
  content: Array<string> | string;
  showRightArrow: boolean;
  showLeftArrow: boolean;
  onClickRight?: () => void;
  onClickLeft?: () => void;
}

export default function PeopleCard(props: Props) {
  return (
    <Container>
      <ImageContainer>
        {props.showLeftArrow === true ? (
          <LeftSmallArrowContainer>
            <ArrowContainer onClick={props.onClickLeft}>
              <LeftSmallArrow style={{ height: "100%" }} />
            </ArrowContainer>
          </LeftSmallArrowContainer>
        ) : null}
        {props.showRightArrow === true ? (
          <RightSmallArrowContainer>
            <ArrowContainer onClick={props.onClickRight}>
              <RightSmallArrow style={{ height: "100%" }} />
            </ArrowContainer>
          </RightSmallArrowContainer>
        ) : null}
        <img
          src={require(`../../../assets/images/people/${props.name}.png`)}
          alt=""
          style={{ width: "100%" }}
        />
      </ImageContainer>
      <BodyContainer>
        <NameContainer>
          <NameTextContainer>
            <div className="inter fs-24 fw-700 tablet:fs-18 phablet:fs-24">
              {`${props.name} `}&nbsp;
            </div>
            <div className="inter fs-24 fw-700 tablet:fs-12 phablet:fs-18">
              {" "}
              ★★★★★
            </div>
          </NameTextContainer>
          <RoleContainer $bgcolor={props.bannerColor}>
            <div
              className="notokr fs-12 fw-350 tablet:fs-9 phablet:fs-12"
              style={{
                color: props.fontColor ? props.fontColor : "black",
              }}
            >
              {props.role}
            </div>
          </RoleContainer>
        </NameContainer>
        <DescContainer>
          <div className="notokr fs-16 fw-350">
            {typeof props.content === "string" ? (
              <div>{props.content}</div>
            ) : (
              props.content.map((line, index) => <div key={index}>{line}</div>)
            )}
          </div>
        </DescContainer>
      </BodyContainer>
    </Container>
  );
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: overflow;
  width: 340px;
  gap: 24px;

  height: 600px;

  ${media.desktop`
    width : 300px;`}

  ${media.tablet`
    width : 200px;`}

  ${media.phablet`
    min-width : 100%;
    max-width : 100%;
    width : 100%;
    height : fit-content;
    aspect-ratio : 1 / 1.5;


    `}
`;

const ImageContainer = styled.div`
  display: flex;
  position: relative;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  gap: 24px;
`;

const NameContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`;

const NameTextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const RoleContainer = styled.div.attrs<{ $bgcolor: string }>((props) => ({
  style: {
    backgroundColor: props.$bgcolor,
  },
}))`
  display: flex;
  height: 22px;
  width: 108px;
  justify-content: center;
  align-items: center;

  ${media.tablet`
    width : 70px;
    `}

  ${media.phablet`
    width : 90px;`}
`;

const DescContainer = styled.div`
  display: flex;
  width: 100%;
`;

const LeftSmallArrowContainer = styled.div`
  display: flex;
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
`;

const RightSmallArrowContainer = styled.div`
  display: flex;
  position: absolute;
  right: -25px;
  top: 50%;
  transform: translateY(-50%);
`;

const ArrowContainer = styled.div`
  display: none;

  ${media.phablet`
    display: flex;
    cursor: pointer;

    padding: 8px;
    margin: -8px;`}
`;
