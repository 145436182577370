import React from "react";
import styled from "styled-components";

import Card0 from "../../../assets/images/impact_0.png";
import Card1 from "../../../assets/images/impact_1.png";
import Card2 from "../../../assets/images/impact_2.png";

import { Background } from "../../../assets/colors";
import { media } from "../../../components/media";

interface Props {
  onHover?: () => void;
  onLeave?: () => void;
  text: string;
  cardIndex: number;
  selected: boolean;
}
export default function ImpactCard(props: Props) {
  return (
    <Container
      onMouseEnter={props.onHover}
      onMouseLeave={props.onLeave}
      $cardIndex={props.cardIndex}
      $selected={props.selected}
    >
      {props.selected ? (
        <img
          src={require(`../../../assets/images/impact_${props.cardIndex}.png`)}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      ) : (
        <img
          src={require(`../../../assets/images/gray.png`)}
          alt=""
          style={{ width: "100%", height: "100%" }}
        />
      )}
      <TextContainer>
        <div
          className="inter fs-36 fw-700 tablet:fs-24"
          style={{
            display: "flex",
            color: "white",
            textAlign: "center",
          }}
        >
          {props.text}
        </div>
      </TextContainer>
    </Container>
  );
}

const Container = styled.div.attrs<{ $selected: boolean; $cardIndex: number }>(
  (props) => ({
    style: {},
  })
)`
  box-sizing: border-box;
  position: relative;
  display: flex;
  max-width: 367px;
  max-height: 367px;
  flex: 1;
  box-sizing: border-box;
  background-color: #141414;
  aspect-ratio: 1/1;

  ${media.phablet`
    width : 100%;
    min-width : 100%;
    height : 100%;

   `}
`;

const TextContainer = styled.div`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  align-items: center;
  justify-content: center;
  padding: 40px;

  ${media.phablet`
    width : calc(100%);
    height : calc(100%);
    `}
`;
