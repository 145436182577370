import * as THREE from "three";

interface Motion {
  left: boolean;
  right: boolean;
  drag: {
    x: number;
    y: number;
  };
  position: {
    x: number;
    y: number;
  };
}

class Mouse {
  private grid: { scale: number; ratio: number };
  private left: boolean;
  private right: boolean;
  private position: THREE.Vector2;
  private motions: Motion[];

  constructor(grid: { scale: number; ratio: number }) {
    this.grid = grid;
    this.left = false;
    this.right = false;
    this.position = new THREE.Vector2();
    this.motions = [];

    document.addEventListener("mousedown", this.mouseDown.bind(this), false);
    document.addEventListener("mouseup", this.mouseUp.bind(this), false);
    document.addEventListener("mousemove", this.mouseMove.bind(this), false);
    document.addEventListener(
      "contextmenu",
      this.contextMenu.bind(this),
      false
    );
  }

  private mouseDown(event: MouseEvent): void {
    // get parent element of event
    const parent = event.target as HTMLElement;
    // get parent of canvas
    const parentParent = parent.parentElement;

    this.position.set(
      event.clientX * this.grid.ratio,
      (event.clientY - (parentParent?.getBoundingClientRect().top ?? 0)) *
        this.grid.ratio
    );
    this.left = event.button === 0 ? true : this.left;
    this.right = event.button === 2 ? true : this.right;
  }

  private mouseUp(event: MouseEvent): void {
    event.preventDefault();
    this.left = event.button === 0 ? false : this.left;
    this.right = event.button === 2 ? false : this.right;
  }

  private mouseMove(event: MouseEvent): void {
    event.preventDefault();

    // get parent element of event
    const parent = event.target as HTMLElement;
    // if parent is not a canvas, return
    if (parent instanceof HTMLCanvasElement === false) return;
    // get parent of canvas
    const parentParent = parent.parentElement;

    const r = this.grid.scale;

    const x = event.clientX * this.grid.ratio;
    const y =
      (event.clientY - (parentParent?.getBoundingClientRect().top ?? 0)) *
      this.grid.ratio;

    const dx = x - this.position.x;
    const dy = y - this.position.y;

    const drag = {
      x: Math.min(Math.max(dx, -r), r),
      y: Math.min(Math.max(dy, -r), r),
    };

    const position = {
      x: x,
      y: y,
    };

    this.motions.push({
      left: this.left,
      right: this.right,
      drag: drag,
      position: position,
    });

    this.position.set(x, y);
  }

  private contextMenu(event: MouseEvent): void {
    event.preventDefault();
  }
}

export { Mouse };
