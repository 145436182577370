import styled from "styled-components";
import Intro1 from "../../assets/images/intro_1.png";
import Intro2 from "../../assets/images/intro_2.png";
import Intro3 from "../../assets/images/intro_3.png";
import GridPattern from "../../components/GridPattern";
import { Pretendard } from "../../assets/fonts";
import { useEffect, useState } from "react";
import ConpapaLogo from "../../assets/images/ConpapaLogo.png";
import { ReactComponent as ChangingCLogo } from "../../assets/icons/ic_clogo.svg";
import * as COLORS from "../../assets/colors";
import TopBar from "../../components/TopBar";

export default function Introduction() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const [isPC, setPC] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1300) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [windowWidth]);

  function text() {
    return (
      <>
        <div
          style={{
            lineHeight: "75px",
            textAlign: "start",
            width: isPC ? "557px" : "323px",
            marginBottom: isPC ? "0px" : "22px",
          }}
        >
          <Pretendard
            color={COLORS.Black}
            size={isPC ? 60 : 40}
            weight={800}
            style={{ letterSpacing: "-0.5px" }}
          >
            스토리의 잠재력을
            <br />
            극대화합니다
          </Pretendard>
        </div>
        <div
          style={{
            lineHeight: "44px",
            textAlign: "start",
            width: isPC ? "557px" : "323px",
            gap: "65px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Pretendard
            color={COLORS.Black}
            size={isPC ? 24 : 18}
            weight={600}
            style={{
              lineHeight: isPC ? "44px" : "30px",
              letterSpacing: "-0.72px",
            }}
          >
            사람들과 공명하는 스토리의 힘을 믿습니다.
            <br />
            그러한 스토리의 잠재력을 극대화하여
            <br />
            모두의 콘텐츠로 만드는 것.
          </Pretendard>
          <Pretendard
            color={COLORS.Black}
            size={isPC ? 24 : 18}
            weight={600}
            style={{ letterSpacing: "-0.72px" }}
          >
            이것이 콘파파의 미션입니다.
          </Pretendard>
        </div>
      </>
    );
  }

  function visionText() {
    return (
      <>
        <VisionTextContainer isPC={isPC}>
          <VisionText isPC={isPC}>
            <Pretendard color={COLORS.Black} size={isPC ? 50 : 30} weight={800}>
              Connect
            </Pretendard>
            <Pretendard
              color={COLORS.Black}
              size={18}
              weight={600}
              style={{ letterSpacing: "-0.54px" }}
            >
              이야기와 이야기, 사람과 사람을{!isPC && <br />} 연결합니다
            </Pretendard>
          </VisionText>
          <VisionText isPC={isPC}>
            <Pretendard color={COLORS.Black} size={isPC ? 50 : 30} weight={800}>
              Create
            </Pretendard>
            <Pretendard
              color={COLORS.Black}
              size={18}
              weight={600}
              style={{ letterSpacing: "-0.54px" }}
            >
              누구나 마음속 이야기를 콘텐츠로{!isPC && <br />} 만들 수 있습니다
            </Pretendard>
          </VisionText>
          <VisionText isPC={isPC}>
            <Pretendard color={COLORS.Black} size={isPC ? 50 : 30} weight={800}>
              Continue
            </Pretendard>
            <Pretendard
              color={COLORS.Black}
              size={18}
              weight={600}
              style={{ letterSpacing: "-0.54px" }}
            >
              하나의 이야기는 새로운 콘텐츠로{!isPC && <br />} 끊임없이
              이어집니다
            </Pretendard>
          </VisionText>
        </VisionTextContainer>
      </>
    );
  }

  function pcBottom() {
    return (
      <>
        <BottomContainer>
          <BottomTopContainer>
            <BottomTopLeftContainer>
              <Pretendard color={COLORS.Black} size={24} weight={600}>
                로고
              </Pretendard>
            </BottomTopLeftContainer>
            <Pretendard size={24} weight={600}>
              핵심가치
            </Pretendard>
          </BottomTopContainer>
          <VisionContainer>
            <img src={ConpapaLogo} width="434px" height="409px" />
            {visionText()}
          </VisionContainer>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ChangingCLogo width={220} height={220} />
          </div>
        </BottomContainer>
      </>
    );
  }

  function mobileBottom() {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "63px",
            marginBottom: "87px",
          }}
        >
          <Pretendard
            color={COLORS.Black}
            size={20}
            weight={600}
            style={{
              width: "360px",
              display: "flex",
              justifyContent: "left",
              textAlign: "start",
            }}
          >
            로고
          </Pretendard>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "42px",
              marginBottom: "128px",
            }}
          >
            <img src={ConpapaLogo} width="226px" height="211px" />
          </div>
          <Pretendard
            color={COLORS.Black}
            size={20}
            weight={600}
            style={{
              width: "360px",
              display: "flex",
              justifyContent: "left",
              textAlign: "start",
              marginBottom: "70px",
            }}
          >
            핵심가치
          </Pretendard>
          <div style={{ marginBottom: "160px" }}>{visionText()}</div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <ChangingCLogo width={140} height={140} />
          </div>
        </div>
      </>
    );
  }

  return (
    <div style={{ overflowX: "hidden" }}>
      <Container>
        <img src={Intro1} width={isPC ? "1440px" : "100%"} height="100%" />
        {isPC ? (
          <MiddleContainer>
            <MiddleLeftContainer>
              <MiddelLeftTextContainer>{text()}</MiddelLeftTextContainer>
              <img src={Intro2} width="703px" height="440px" />
            </MiddleLeftContainer>
            <img src={Intro3} width="707px" height="938px" />
          </MiddleContainer>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              {text()}
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "24px",
                marginTop: "40px",
              }}
            >
              <img src={Intro3} width="360px" height="478px" />
              <img src={Intro2} width="360px" height="225px" />
            </div>
          </>
        )}
      </Container>
      {isPC ? pcBottom() : mobileBottom()}
    </div>
  );
}

const Container = styled.div`
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 26px;
`;

const MiddleContainer = styled.div`
  display: flex;
  gap: 30px;
  height: 938px;
  overflow: hidden;
`;

const MiddleLeftContainer = styled.div`
  padding-top: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  height: 938px;
  gap: 57px;
`;

const MiddelLeftTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 29px;
  width: 557px;
`;

const BottomContainer = styled.div`
  background: #ffffff;
  padding: 150px 0px 150px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BottomTopContainer = styled.div`
  width: 60vw;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 500px;
`;

const BottomTopLeftContainer = styled.div`
  display: flex;
  width: 164px;
  justify-content: space-between;
  align-items: center;
`;

const VisionContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 69px;
  gap: 14vw;
  margin-bottom: 242px;
`;

interface VisionTextContainerProps {
  isPC: boolean;
}

const VisionTextContainer = styled.div<VisionTextContainerProps>`
  display: flex;
  flex-direction: column;
  justify-content: ${(props) => (props.isPC ? "start" : "center")};
  align-items: center;
  text-align: start;
  gap: 43px;
`;

interface VisionTextProps {
  isPC: boolean;
}

const VisionText = styled.div<VisionTextProps>`
  width: ${(props) => (props.isPC ? 400 : 250)}px;
  height: 106px;
  gap: 6px;
`;
