import React, { useEffect } from "react";
import styled from "styled-components";

import * as COLORS from "../assets/colors";

export interface PrimaryButtonComponentProps {
  width: number;
  height: number;
  children: React.ReactNode;
  buttonColor?: string;
  shadowColor?: string;
  buttonDisabledColor?: string;
  borderColor?: string;
  disabled?: boolean;
  onClick?: () => void;
}
export default function PrimaryButtonComponent(
  props: PrimaryButtonComponentProps
) {
  return (
    <Container
      width={props.width}
      height={props.height}
      onClick={props.disabled !== true ? props.onClick : undefined}
      borderColor={props.borderColor}
      shadowColor={props.shadowColor}
      disabled={props.disabled}
    >
      <InnerContainer
        width={props.width - 2}
        height={props.height - 2}
        buttonColor={props.buttonColor}
        disabled={props.disabled}
        buttonDisabledColor={props.buttonDisabledColor}
      >
        {props.children}
      </InnerContainer>
    </Container>
  );
}

interface ContainerProps {
  width: number;
  height: number;
  borderColor?: string;
  shadowColor?: string;
  disabled?: boolean;
}
const Container = styled.div<ContainerProps>`
  display: flex;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 2px;
  border: 1px solid ${(props) => props.borderColor ?? COLORS.Gray700};
  background-color: ${(props) => props.shadowColor ?? COLORS.Orange100};
  padding: 0px 2px 2px 0px;
  align-items: center;
  justify-content: center;
  gap: 10px;
  ${(props) =>
    props.disabled !== true &&
    `:hover {
            cursor: pointer;
        }`}
`;

interface InnerContainerProps {
  width: number;
  height: number;
  buttonColor?: string;
  buttonDisabledColor?: string;
  disabled?: boolean;
}

const InnerContainer = styled.div<InnerContainerProps>`
  display: flex;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  gap: 2px;
  background-color: ${(props) =>
    !props.disabled
      ? props.buttonColor ?? COLORS.OffWhite
      : props.buttonDisabledColor ?? COLORS.Gray300};
  border-radius: 1px;
  align-items: center;
  justify-content: center;
`;
