import Text from "../../components/Text";
import Spacer from "../../components/Spacer";
import axios from "axios";
import React, { useState, useEffect } from "react";
import GridPattern from "../../components/GridPattern";
import styled from "styled-components";
import EmailSection from "./EmailSection";
import SubmitFormSection from "./EmailSection/SubmitFormSection";
import { media } from "../../components/media";

export default function Contact() {
  const [title, setTitle] = useState<string>("");
  const [content, setContent] = useState<string>("");
  const [email, setEmail] = useState<string>("");

  async function submitContact() {
    try {
      const response = await axios.post(
        "https://05yxp48fke.execute-api.ap-northeast-2.amazonaws.com/default",
        {
          title: title,
          content: content,
          email: email,
        }
      );
      alert("문의해 주셔서 감사합니다!");

      setTitle("");
      setContent("");
      setEmail("");
    } catch (err) {
      alert("오류가 발생했습니다 : " + err);
    }
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  return (
    <Container>
      <MainContainer>
        <div
          className="inter fs-64 fw-500 phone:fs-24 phablet:fs-36 tablet:fs-60"
          style={{ textAlign: "center" }}
        >
          <LineBreakSpan>Together,</LineBreakSpan> we are{" "}
          <span className="shrik fw-400">Forming </span> a brighter future
        </div>
        <div className="inter fs-24 fw-300 phone:fs-14 phablet:fs-18 tablet:fs-20">
          We're here for you, with open ears, attentive eyes, and welcoming
          arms.
        </div>
      </MainContainer>
      <HorizDivider />
      <EmailSection />
      <HorizDivider />
      <SubmitFormSection />
      <HorizDivider />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  overflow: hidden;
  width: 100%;
  max-width: 1440px;
  box-sizing: border-box;
  padding: 0px 80px;
  margin: 0 auto;

  ${media.phablet`
    padding : 0px 20px;`}
`;

const MainContainer = styled.div`
  display: flex;
  width: 100%;
  padding: 350px 0px 0px 0px;
  text-align: center;
  flex-direction: column;
  gap: 20px;

  align-items: center;
  ${media.phablet`
    padding : 160px 20px 0px 20px;`}
`;

const LineBreakSpan = styled.span`
  display: block;
  justify-content: center;

  ${media.phablet`
    display : unset;
  `}
`;

const HorizDivider = styled.div`
  height: 250px;

  ${media.phablet`
    height : 100px;
  `}
`;
