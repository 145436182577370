import React, { useEffect } from "react";
import styled from "styled-components";
import FirstPage from "./FirstPage";
import { ReactComponent as RightArrow } from "../../../assets/icons/page-arrow-right.svg";
import { ReactComponent as LeftArrow } from "../../../assets/icons/page-arrow-left.svg";
import SecondPage from "./SecondPage";
import { media } from "../../../components/media";

interface Props {
  width: number;
}
export default function FormingSection(props: Props) {
  const [pageIndex, setPageIndex] = React.useState<number | null>(null);
  const pageContainerRef = React.useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (pageIndex == null) return;

    if (pageIndex === 1) {
      // add animation to left of firstPageRef
      pageContainerRef.current?.animate(
        [{ transform: "translateX(0)" }, { transform: "translateX(-100%)" }],
        {
          duration: 500,
          easing: "ease-in-out",
          fill: "forwards",
        }
      );
    } else {
      // add animation to right of firstPageRef
      pageContainerRef.current?.animate(
        [{ transform: "translateX(-100%)" }, { transform: "translateX(0)" }],
        {
          duration: 500,
          easing: "ease-in-out",
          fill: "forwards",
        }
      );
    }
  }, [pageIndex]);

  useEffect(() => {
    setPageIndex(0);
  }, [props.width]);

  const touchXRef = React.useRef<number | null>(null);

  function handleTouchStart(e: React.TouchEvent) {
    if (props.width >= 800) return;
    touchXRef.current = e.touches[0].clientX;
  }

  function handleTouchMove(e: React.TouchEvent) {
    if (touchXRef.current == null) return;
    if (props.width >= 800) return;

    const diff = touchXRef.current - e.touches[0].clientX;

    if (diff > 60 && (pageIndex === 0 || pageIndex == null)) {
      setPageIndex(1);
      touchXRef.current = null;
    } else if (diff < -60 && pageIndex === 1) {
      setPageIndex(0);
      touchXRef.current = null;
    }
  }

  function handleTouchEnd() {
    if (props.width >= 800) return;
    touchXRef.current = null;
  }

  return (
    <Container
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <PageContainer ref={pageContainerRef}>
        <div style={{ width: "100%", minWidth: "100%", height: "100%" }}>
          <FirstPage width={props.width} />
        </div>
        <div style={{ width: "100%", minWidth: "100%", height: "100%" }}>
          <SecondPage />
        </div>
      </PageContainer>

      <MoreContainer>
        <div
          style={{ padding: 5, margin: -5, cursor: "pointer" }}
          onClick={() => setPageIndex(0)}
        >
          <Dot
            $color={
              pageIndex === 1 || pageIndex == null
                ? "rgb(219, 219, 219)"
                : "rgb(170, 170, 170)"
            }
          />
        </div>
        <div
          style={{ padding: 5, margin: -5, cursor: "pointer" }}
          onClick={() => setPageIndex(1)}
        >
          <Dot
            $color={
              pageIndex === 1 || pageIndex == null
                ? "rgb(170, 170, 170)"
                : "rgb(219, 219, 219)"
            }
          />
        </div>
      </MoreContainer>
      {pageIndex === 0 || pageIndex == null ? (
        <RightArrowContainer onClick={() => setPageIndex(1)}>
          <ArrowContainer>
            <RightArrow />
          </ArrowContainer>
        </RightArrowContainer>
      ) : null}
      {pageIndex === 1 ? (
        <LeftArrowContainer onClick={() => setPageIndex(0)}>
          <ArrowContainer>
            <LeftArrow />
          </ArrowContainer>
        </LeftArrowContainer>
      ) : null}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  max-width: 1440px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-sizing: border-box;

  margin: 300px auto 0px auto;

  height: 640px;
  background: linear-gradient(0deg, #f0f0f0 0%, #ffffff 100%);

  ${media.tablet`
   height : fit-content;
   
    `}

  ${media.phablet`
      margin : 0px auto;
      `}
`;

const PageContainer = styled.div`
  display: flex;
  width: 100%;

  ${media.tablet`
    padding-top : 100px;`}
`;

const MoreContainer = styled.div`
  display: flex;
  position: absolute;

  width: 100%;
  justify-content: center;

  bottom: 20px;
  gap: 8px;
`;

const Dot = styled.div.attrs<{ $color: string }>((props) => ({
  style: {
    backgroundColor: props.$color,
  },
}))`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const RightArrowContainer = styled.div`
  display: flex;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;

  &:hover {
    cursor: pointer;
  }

  ${media.tablet`
    right : -5px;
    padding : 20px;
    margin : -20px;
    `}
`;

const ArrowContainer = styled.div`
  scale: 0.7;
  ${media.tablet`
    scale : 0.5;`}
  ${media.phone`
  scale : 0.25;
  `}
`;

const LeftArrowContainer = styled.div`
  display: flex;
  position: absolute;
  left: 20px;
  top: 50%;
  transform: translateY(-50%);
  opacity: 0.5;

  &:hover {
    cursor: pointer;
  }

  ${media.phone`
    left : -5px;
    padding : 20px;
    margin : -20px;
    `}
`;
