import React from "react";
import styled from "styled-components";
import { ReactComponent as RightArrow } from "../assets/icons/ButtonArrow.svg";
import { media } from "./media";

interface Props {
  text: string;
  bgcolor: string;
  textcolor: string;
  fontSize: number;
  mobileFontSize?: number;
  onClick?: () => void;
  disabled?: boolean;
}
export default function SquareButton(props: Props) {
  const [ratio, setRatio] = React.useState(1);
  const intervalRef = React.useRef<NodeJS.Timeout | null>(null);
  const decrementRef = React.useRef<boolean>(true);

  function handleHover() {
    if (props.disabled === true) return;

    intervalRef.current = setInterval(() => {
      setRatio((prev) => {
        if (prev > 1.0) {
          if (intervalRef.current != null) clearInterval(intervalRef.current);
          intervalRef.current = null;
          decrementRef.current = true;
          return 1.0;
        } else if (prev < 0.0) {
          decrementRef.current = false;
          return 0;
        }

        return prev + (decrementRef.current === true ? -1 : 1) * 0.04;
      });
    }, 10);
  }

  function handleLeave() {
    if (intervalRef.current != null) clearInterval(intervalRef.current);
    intervalRef.current = null;
    decrementRef.current = true;
    setRatio(1);
  }

  return (
    <Container
      $color={props.bgcolor}
      $disabled={props.disabled}
      $ratio={ratio}
      onClick={props.onClick}
      onMouseEnter={handleHover}
      onMouseLeave={handleLeave}
    >
      <div
        className={`inter fs-${props.fontSize} fw-700 ${
          props.mobileFontSize ? `phablet:fs-${props.mobileFontSize}` : ""
        }`}
        style={{ color: props.textcolor }}
      >
        {props.text}
      </div>
      <ArrowContainer
        $fontSize={props.fontSize}
        $mobileFontSize={props.mobileFontSize}
      >
        <RightArrow style={{ height: "100%" }} />
      </ArrowContainer>
    </Container>
  );
}

const Container = styled.div.attrs<{
  $color: string;
  $disabled?: boolean;
  $ratio: number;
}>((props) => ({
  style: {
    background: props.$color,
    opacity: props.$disabled ? 0.5 : 1,
    cursor: props.$disabled ? "default" : "pointer",
    backgroundImage: `linear-gradient(90deg, black 0%, black ${
      props.$ratio * 100
    }%, white ${props.$ratio * 100}%, white 100%)`,
  },
}))`
  display: flex;
  width: fit-content;
  height: fit-content;
  box-sizing: border-box;

  padding: 10px 80px 10px 20px;
  align-items: center;
  justify-content: center;
  gap: 4px;

  white-space: nowrap;

  transition: all 0.3s;

  ${media.phablet`
    padding : 4px 16px 4px 8px;
    gap : 0;
    `}
`;

const ArrowContainer = styled.div.attrs<{
  $fontSize: number;
  $mobileFontSize?: number;
}>((props) => ({}))`
  display: flex;
  height: ${(props) => props.$fontSize * 0.7}px;

  ${(props) =>
    props.$mobileFontSize != null
      ? media.phablet`
      height : ${props.$mobileFontSize * 0.7}px;
    `
      : null}
`;
