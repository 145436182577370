import React from "react";
import styled from "styled-components";
import Toodna from "./Toonda";
import Reat from "./Reat";
import { media } from "../../../components/media";

interface Props {
  width: number;
}
export default function ProductSection(props: Props) {
  return (
    <Container id="products">
      <Toodna width={props.width} />
      <HorizLine />
      <Reat width={props.width} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  gap: 240px;

  ${media.phablet`
    padding : 0px 20px;
    gap : 40px;
  `}
`;

const HorizLine = styled.div`
  width: 100%;
  height: 2px;
  background: black;
`;
