import * as THREE from "three";
import { Slab } from "./Slab";
import { Advect } from "./slabop/Advect";
import { Jacobi } from "./slabop/Jacobi";
import { Divergence } from "./slabop/Divergence";
import { Gradient } from "./slabop/Gradient";
import { Splat } from "./slabop/Splat";
import { Vorticity } from "./slabop/Vorticity";
import { VorticityConfinement } from "./slabop/VorticityConfinement";
import { Boundary } from "./slabop/Boundary";

export interface Slabs {
  velocity: Slab;
  density: Slab;
  velocityDivergence: Slab;
  velocityVorticity: Slab;
  pressure: Slab;
}

export interface SlabOperations {
  advect: Advect;
  diffuse: Jacobi;
  divergence: Divergence;
  poissonPressureEq: Jacobi;
  gradient: Gradient;
  splat: Splat;
  vorticity: Vorticity;
  vorticityConfinement: VorticityConfinement;
  boundary: Boundary;
}

// const RANDOM_MOTIONS = [
//   [
//     {
//       position: {
//         x: 509.56190476190477,
//         y: 116.21587301587302,
//       },
//       drag: {
//         x: 1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 503.87301587301585,
//         y: 121.09206349206349,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 498.9968253968254,
//         y: 124.34285714285714,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 497.37142857142857,
//         y: 126.78095238095239,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 495.74603174603175,
//         y: 128.4063492063492,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 493.3079365079365,
//         y: 130.03174603174602,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 491.6825396825397,
//         y: 130.84444444444443,
//       },
//       drag: {
//         x: -1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 488.43174603174606,
//         y: 134.0952380952381,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 484.36825396825395,
//         y: 136.53333333333333,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 481.1174603174603,
//         y: 138.97142857142856,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 479.4920634920635,
//         y: 140.5968253968254,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 377.9047619047619,
//         y: 228.36825396825398,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 372.215873015873,
//         y: 233.24444444444444,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 369.77777777777777,
//         y: 235.68253968253967,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 367.33968253968254,
//         y: 238.12063492063493,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 364.0888888888889,
//         y: 240.55873015873016,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 360.83809523809526,
//         y: 243.8095238095238,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 358.4,
//         y: 246.24761904761905,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 355.14920634920634,
//         y: 249.4984126984127,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 352.7111111111111,
//         y: 251.93650793650792,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 350.2730158730159,
//         y: 254.37460317460318,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 347.83492063492065,
//         y: 257.6253968253968,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 345.3968253968254,
//         y: 260.87619047619046,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 341.3333333333333,
//         y: 264.12698412698415,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 338.8952380952381,
//         y: 267.3777777777778,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 335.64444444444445,
//         y: 271.44126984126984,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 332.3936507936508,
//         y: 275.5047619047619,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//   ],
//   [
//     {
//       position: {
//         x: 329.14285714285717,
//         y: 279.56825396825394,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 325.89206349206347,
//         y: 284.44444444444446,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 322.64126984126983,
//         y: 287.6952380952381,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 320.2031746031746,
//         y: 292.57142857142856,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 316.95238095238096,
//         y: 296.63492063492066,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 314.51428571428573,
//         y: 301.5111111111111,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 312.0761904761905,
//         y: 304.76190476190476,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 308.8253968253968,
//         y: 309.6380952380952,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 306.3873015873016,
//         y: 313.7015873015873,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 304.76190476190476,
//         y: 317.7650793650794,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 302.32380952380953,
//         y: 323.45396825396824,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 299.8857142857143,
//         y: 327.5174603174603,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 296.63492063492066,
//         y: 335.64444444444445,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 295.0095238095238,
//         y: 341.3333333333333,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 293.38412698412696,
//         y: 344.584126984127,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 291.75873015873015,
//         y: 348.64761904761906,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 290.94603174603174,
//         y: 353.5238095238095,
//       },
//       drag: {
//         x: -0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 289.3206349206349,
//         y: 357.58730158730157,
//       },
//       drag: {
//         x: -1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 288.5079365079365,
//         y: 361.6507936507937,
//       },
//       drag: {
//         x: -0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 287.6952380952381,
//         y: 366.52698412698413,
//       },
//       drag: {
//         x: -0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 286.8825396825397,
//         y: 369.77777777777777,
//       },
//       drag: {
//         x: -0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 286.0698412698413,
//         y: 373.0285714285714,
//       },
//       drag: {
//         x: -0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 377.0920634920635,
//       },
//       drag: {
//         x: -0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 381.15555555555557,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 385.2190476190476,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 388.46984126984125,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 391.7206349206349,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 393.34603174603177,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 396.5968253968254,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 285.25714285714287,
//         y: 399.03492063492064,
//       },
//       drag: {
//         x: 0,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 286.0698412698413,
//         y: 403.0984126984127,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 286.8825396825397,
//         y: 405.5365079365079,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 287.6952380952381,
//         y: 407.97460317460315,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 289.3206349206349,
//         y: 409.6,
//       },
//       drag: {
//         x: 1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 290.1333333333333,
//         y: 411.22539682539684,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 290.94603174603174,
//         y: 412.85079365079366,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 292.57142857142856,
//         y: 414.4761904761905,
//       },
//       drag: {
//         x: 1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 294.1968253968254,
//         y: 415.2888888888889,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 295.0095238095238,
//         y: 416.9142857142857,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 297.44761904761907,
//         y: 417.7269841269841,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 299.8857142857143,
//         y: 419.35238095238094,
//       },
//       drag: {
//         x: 1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 300.6984126984127,
//         y: 419.35238095238094,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 303.94920634920635,
//         y: 420.16507936507935,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 305.57460317460317,
//         y: 420.97777777777776,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 308.8253968253968,
//         y: 420.97777777777776,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 311.26349206349204,
//         y: 421.79047619047617,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 313.7015873015873,
//         y: 421.79047619047617,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 316.95238095238096,
//         y: 421.79047619047617,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 320.2031746031746,
//         y: 421.79047619047617,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 324.26666666666665,
//         y: 421.79047619047617,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 327.5174603174603,
//         y: 422.6031746031746,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 331.5809523809524,
//         y: 422.6031746031746,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 334.83174603174604,
//         y: 423.415873015873,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 338.8952380952381,
//         y: 423.415873015873,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 342.95873015873013,
//         y: 424.22857142857146,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984667,
//       },
//     },
//     {
//       position: {
//         x: 347.83492063492065,
//         y: 425.04126984126987,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 351.8984126984127,
//         y: 425.8539682539683,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 357.58730158730157,
//         y: 426.6666666666667,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 362.4634920634921,
//         y: 427.4793650793651,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 368.15238095238095,
//         y: 429.1047619047619,
//       },
//       drag: {
//         x: 1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 373.8412698412698,
//         y: 429.9174603174603,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 378.71746031746034,
//         y: 430.73015873015873,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 384.4063492063492,
//         y: 432.35555555555555,
//       },
//       drag: {
//         x: 1,
//         y: 1,
//       },
//     },
//     {
//       position: {
//         x: 389.28253968253966,
//         y: 432.35555555555555,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 394.9714285714286,
//         y: 433.16825396825396,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 399.84761904761905,
//         y: 433.9809523809524,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 404.7238095238095,
//         y: 433.9809523809524,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 409.6,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 414.4761904761905,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 419.35238095238094,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 425.04126984126987,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 430.73015873015873,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 435.6063492063492,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 442.10793650793653,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 451.86031746031745,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 455.1111111111111,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//   ],
//   [
//     {
//       position: {
//         x: 461.6126984126984,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 468.1142857142857,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 474.61587301587304,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 481.1174603174603,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 486.8063492063492,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 493.3079365079365,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 499.8095238095238,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 505.4984126984127,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 512,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 518.5015873015873,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 524.1904761904761,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 529.8793650793651,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 535.568253968254,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 540.4444444444445,
//         y: 435.6063492063492,
//       },
//       drag: {
//         x: 1,
//         y: 0,
//       },
//     },
//     {
//       position: {
//         x: 546.1333333333333,
//         y: 434.7936507936508,
//       },
//       drag: {
//         x: 1,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 551.0095238095238,
//         y: 433.9809523809524,
//       },
//       drag: {
//         x: 1,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 555.0730158730158,
//         y: 433.16825396825396,
//       },
//       drag: {
//         x: 1,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 559.9492063492063,
//         y: 432.35555555555555,
//       },
//       drag: {
//         x: 1,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 564.0126984126985,
//         y: 430.73015873015873,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 568.8888888888889,
//         y: 429.9174603174603,
//       },
//       drag: {
//         x: 1,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 572.952380952381,
//         y: 428.2920634920635,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 577.015873015873,
//         y: 426.6666666666667,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 580.2666666666667,
//         y: 425.8539682539683,
//       },
//       drag: {
//         x: 1,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 584.3301587301587,
//         y: 424.22857142857146,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 587.5809523809523,
//         y: 422.6031746031746,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 591.6444444444444,
//         y: 420.16507936507935,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 594.8952380952381,
//         y: 418.53968253968253,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 598.9587301587302,
//         y: 416.9142857142857,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 602.2095238095238,
//         y: 414.4761904761905,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 605.4603174603175,
//         y: 412.03809523809525,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 608.7111111111111,
//         y: 409.6,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 611.1492063492063,
//         y: 407.16190476190474,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 614.4,
//         y: 404.7238095238095,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 617.6507936507936,
//         y: 401.47301587301587,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 620.9015873015873,
//         y: 399.03492063492064,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 623.3396825396825,
//         y: 395.784126984127,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 625.7777777777778,
//         y: 391.7206349206349,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 629.0285714285715,
//         y: 388.46984126984125,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 631.4666666666667,
//         y: 385.2190476190476,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 634.7174603174603,
//         y: 381.15555555555557,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 637.1555555555556,
//         y: 377.0920634920635,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 638.7809523809524,
//         y: 374.65396825396823,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 641.2190476190476,
//         y: 370.5904761904762,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 643.6571428571428,
//         y: 367.33968253968254,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 646.0952380952381,
//         y: 364.0888888888889,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 647.7206349206349,
//         y: 360.83809523809526,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 649.3460317460317,
//         y: 358.4,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 651.7841269841269,
//         y: 355.14920634920634,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 653.4095238095238,
//         y: 351.8984126984127,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 655.847619047619,
//         y: 348.64761904761906,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 656.6603174603175,
//         y: 346.20952380952383,
//       },
//       drag: {
//         x: 0.8126984126985235,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 659.0984126984127,
//         y: 342.95873015873013,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 660.7238095238096,
//         y: 340.5206349206349,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 662.3492063492064,
//         y: 337.26984126984127,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 663.9746031746032,
//         y: 334.0190476190476,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 666.4126984126984,
//         y: 330.768253968254,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 668.8507936507937,
//         y: 327.5174603174603,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 671.2888888888889,
//         y: 322.64126984126983,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//   ],
//   [
//     {
//       position: {
//         x: 675.3523809523809,
//         y: 315.32698412698414,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 677.7904761904762,
//         y: 312.8888888888889,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 681.0412698412698,
//         y: 307.2,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 683.479365079365,
//         y: 301.5111111111111,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 686.7301587301587,
//         y: 295.8222222222222,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 689.9809523809524,
//         y: 290.1333333333333,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 693.2317460317461,
//         y: 284.44444444444446,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 695.6698412698413,
//         y: 279.56825396825394,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 698.1079365079365,
//         y: 274.6920634920635,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 700.5460317460318,
//         y: 269.815873015873,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 702.984126984127,
//         y: 264.93968253968256,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 704.6095238095238,
//         y: 260.87619047619046,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 707.047619047619,
//         y: 256,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 708.6730158730159,
//         y: 251.1238095238095,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 711.1111111111111,
//         y: 246.24761904761905,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 711.9238095238095,
//         y: 241.37142857142857,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 713.5492063492063,
//         y: 236.4952380952381,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 715.1746031746031,
//         y: 231.61904761904762,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 716.8,
//         y: 225.93015873015872,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 717.6126984126984,
//         y: 221.05396825396826,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 719.2380952380952,
//         y: 216.17777777777778,
//       },
//       drag: {
//         x: 1,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 720.0507936507936,
//         y: 212.11428571428573,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 720.8634920634921,
//         y: 208.05079365079365,
//       },
//       drag: {
//         x: 0.8126984126985235,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 721.6761904761905,
//         y: 203.98730158730157,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 722.4888888888889,
//         y: 199.92380952380952,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 722.4888888888889,
//         y: 196.67301587301588,
//       },
//       drag: {
//         x: 0,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 723.3015873015873,
//         y: 192.6095238095238,
//       },
//       drag: {
//         x: 0.8126984126984098,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 723.3015873015873,
//         y: 190.984126984127,
//       },
//       drag: {
//         x: 0,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 723.3015873015873,
//         y: 189.35873015873017,
//       },
//       drag: {
//         x: 0,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 723.3015873015873,
//         y: 186.9206349206349,
//       },
//       drag: {
//         x: 0,
//         y: -1,
//       },
//     },
//     {
//       position: {
//         x: 723.3015873015873,
//         y: 186.1079365079365,
//       },
//       drag: {
//         x: 0,
//         y: -0.8126984126984098,
//       },
//     },
//     {
//       position: {
//         x: 723.3015873015873,
//         y: 177.16825396825396,
//       },
//       drag: {
//         x: 0,
//         y: -1,
//       },
//     },
//   ],
// ];

export class Solver {
  private readonly grid: any;
  private readonly time: any;
  private readonly windowSize: { x: number; y: number };
  private readonly velocity: Slab;
  public density: Slab;
  private readonly velocityDivergence: Slab;
  private readonly velocityVorticity: Slab;
  private readonly pressure: Slab;
  private readonly advect: Advect;
  private readonly diffuse: Jacobi;
  private readonly divergence: Divergence;
  private readonly poissonPressureEq: Jacobi;
  private readonly gradient: Gradient;
  private readonly splat: Splat;
  private readonly vorticity: Vorticity;
  private readonly vorticityConfinement: VorticityConfinement;
  private readonly boundary: Boundary;
  private readonly viscosity: number;
  private readonly applyViscosity: boolean;
  private readonly applyVorticity: boolean;
  private readonly source: THREE.Vector3;
  public ink: THREE.Vector3;
  private lastRandomPoint: THREE.Vector2 | null;
  private frame = 0;
  private motionArray: any[];

  constructor(
    grid: any,
    time: any,
    windowSize: { x: number; y: number },
    slabs: Slabs,
    slabop: SlabOperations
  ) {
    this.grid = grid;
    this.time = time;
    this.windowSize = windowSize;

    // slabs
    this.velocity = slabs.velocity;
    this.density = slabs.density;
    this.velocityDivergence = slabs.velocityDivergence;
    this.velocityVorticity = slabs.velocityVorticity;
    this.pressure = slabs.pressure;

    // slab operations
    this.advect = slabop.advect;
    this.diffuse = slabop.diffuse;
    this.divergence = slabop.divergence;
    this.poissonPressureEq = slabop.poissonPressureEq;
    this.gradient = slabop.gradient;
    this.splat = slabop.splat;
    this.vorticity = slabop.vorticity;
    this.vorticityConfinement = slabop.vorticityConfinement;
    this.boundary = slabop.boundary;

    this.viscosity = 0.0001;
    this.applyViscosity = false;
    this.applyVorticity = false;

    // density attributes
    this.source = new THREE.Vector3(0.8, 0.0, 0.0);
    this.ink = new THREE.Vector3(0.1, 0.1, 0.1);

    this.lastRandomPoint = null;

    this.motionArray = [];
  }

  public step(renderer: any, mouse: any): void {
    const temp = this.advect.dissipation;
    this.advect.dissipation = 1;
    this.advect.compute(renderer, this.velocity, this.velocity, this.velocity);
    this.boundary.compute(renderer, this.velocity, -1, this.velocity);

    this.advect.dissipation = temp;
    this.advect.compute(renderer, this.velocity, this.density, this.density);

    this.addForces(renderer, mouse);

    if (this.applyVorticity) {
      this.vorticity.compute(renderer, this.velocity, this.velocityVorticity);
      this.vorticityConfinement.compute(
        renderer,
        this.velocity,
        this.velocityVorticity,
        this.velocity
      );
      this.boundary.compute(renderer, this.velocity, -1, this.velocity);
    }

    if (this.applyViscosity && this.viscosity > 0) {
      const s = this.grid.scale;

      this.diffuse.alpha = (s * s) / (this.viscosity * this.time.step);
      this.diffuse.beta = 4 + this.diffuse.alpha;
      this.diffuse.compute(
        renderer,
        this.velocity,
        this.velocity,
        this.velocity,
        this.boundary,
        -1
      );
    }

    this.project(renderer);
  }

  private addForces(renderer: any, mouse: any): void {
    const point = new THREE.Vector2();
    const force = new THREE.Vector3();

    // // apply mouse forces with mouse movements
    // for (const motion of mouse.motions) {
    //   point.set(motion.position.x, this.windowSize.y - motion.position.y);
    //   point.x = (point.x / this.windowSize.x) * this.grid.size.x;
    //   point.y = (point.y / this.windowSize.y) * this.grid.size.y;

    //   force.set(motion.drag.x, -motion.drag.y, 0);
    //   this.splat.compute(renderer, this.velocity, force, point, this.velocity);
    //   this.boundary.compute(renderer, this.velocity, -1, this.velocity);

    //   this.splat.compute(
    //     renderer,
    //     this.density,
    //     this.source,
    //     point,
    //     this.density
    //   );
    // }

    // mouse.motions = [];
    if (mouse.motions.length > 0) {
      const motions = mouse.motions;

      for (const motion of motions) {
        // this.motionArray.push({ position: motion.position, drag: motion.drag });
        point.set(motion.position.x, this.windowSize.y - motion.position.y);
        point.x = (point.x / this.windowSize.x) * this.grid.size.x;
        point.y = (point.y / this.windowSize.y) * this.grid.size.y;

        force.set(motion.drag.x, -motion.drag.y, 0);
        this.splat.compute(
          renderer,
          this.velocity,
          force,
          point,
          this.velocity
        );
        this.boundary.compute(renderer, this.velocity, -1, this.velocity);

        this.splat.compute(
          renderer,
          this.density,
          this.source,
          point,
          this.density
        );
      }
    }
    mouse.motions = [];

    this.frame++;

    // console.log(this.motionArray);
  }

  private project(renderer: any): void {
    this.divergence.compute(renderer, this.velocity, this.velocityDivergence);
    this.clearSlab(renderer, this.pressure);

    this.poissonPressureEq.alpha = -this.grid.scale * this.grid.scale;
    this.poissonPressureEq.compute(
      renderer,
      this.pressure,
      this.velocityDivergence,
      this.pressure,
      this.boundary,
      1
    );

    this.gradient.compute(
      renderer,
      this.pressure,
      this.velocity,
      this.velocity
    );
    this.boundary.compute(renderer, this.velocity, -1, this.velocity);
  }

  private clearSlab(renderer: any, slab: Slab): void {
    renderer.setRenderTarget(slab.write);
    renderer.clear();
    renderer.setRenderTarget(null);
    slab.swap();
  }

  public static make(
    grid: any,
    time: any,
    windowSize: { x: number; y: number },
    shaders: any
  ): Solver {
    const w = grid.size.x;
    const h = grid.size.y;

    const slabs: Slabs = {
      velocity: Slab.make(w, h),
      density: Slab.make(w, h),
      velocityDivergence: Slab.make(w, h),
      velocityVorticity: Slab.make(w, h),
      pressure: Slab.make(w, h),
    };

    const slabop: SlabOperations = {
      advect: new Advect(shaders.advect, grid, time),
      diffuse: new Jacobi(shaders.jacobivector, grid),
      divergence: new Divergence(shaders.divergence, grid),
      poissonPressureEq: new Jacobi(shaders.jacobiscalar, grid),
      gradient: new Gradient(shaders.gradient, grid),
      splat: new Splat(shaders.splat, grid),
      vorticity: new Vorticity(shaders.vorticity, grid),
      vorticityConfinement: new VorticityConfinement(
        shaders.vorticityforce,
        grid,
        time
      ),
      boundary: new Boundary(shaders.boundary, grid),
    };

    return new Solver(grid, time, windowSize, slabs, slabop);
  }
}
