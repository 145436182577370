import React, { useEffect, useState } from "react";

import styled, { css, keyframes } from "styled-components";
import InsideHeaderButton from "./InsideHeaderButton";
import { useNavigate } from "react-router-dom";
import { media } from "../media";

interface Props {
  show: boolean;
}
export default function HeaderMenu(props: Props) {
  const [shouldAnimate, setShouldAnimate] = useState(false);
  const [animating, setAnimating] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // show가 변경될 때 애니메이션 실행
    if (props.show) {
      setShouldAnimate(true);
    }
  }, [props.show]);

  // animate on show
  return (
    <Container $show={props.show} $animate={shouldAnimate}>
      <InnerContainer>
        <DummyContainer></DummyContainer>
        <HeaderItemColumnContainer>
          <OnlyMobileContainer>
            <InsideHeaderButton
              text="Company"
              width={90}
              onClick={() => navigate("/company")}
              bold={true}
            />
          </OnlyMobileContainer>
          <InsideHeaderButton
            text="Our Culture"
            width={90}
            onClick={() => navigate("/company#our-culture")}
          />
          <InsideHeaderButton
            text="Careers"
            width={90}
            onClick={() => navigate("/company#careers")}
          />
        </HeaderItemColumnContainer>
        <HeaderItemColumnContainer>
          <OnlyMobileContainer>
            <InsideHeaderButton
              text="Products"
              width={90}
              onClick={() => navigate("/#products")}
              bold={true}
            />
          </OnlyMobileContainer>
          <InsideHeaderButton
            text="TOONDA"
            width={90}
            onClick={() => navigate("/#toonda")}
          />
          <InsideHeaderButton
            text="reat"
            width={90}
            onClick={() => navigate("/#reat")}
          />
        </HeaderItemColumnContainer>
        <HeaderItemColumnContainer>
          <OnlyMobileContainer>
            <InsideHeaderButton
              text="Contact"
              width={90}
              onClick={() => navigate("/contact")}
              bold={true}
            />
          </OnlyMobileContainer>
          <InsideHeaderButton
            text="Business"
            width={90}
            onClick={() => navigate("/contact#business")}
          />
        </HeaderItemColumnContainer>
      </InnerContainer>
    </Container>
  );
}

const heightIncreases = keyframes`
  from {
    height: 0px;
  }
    to {
        height: 240px;
    }     
  `;

const heightDecreases = keyframes`
  from {
    height: 240px;
  }
    to {
        height: 0px;
    }     
  `;

const DummyContainer = styled.div`
  width: 137px;

  ${media.phablet`
    display : none;
    `}
`;
const Container = styled.div.attrs<{ $show: boolean; $animate: boolean }>(
  (props) => ({})
)`
  display: flex;
  position: relative;
  width: 100%;
  height: 0px;

  justify-content: center;

  background-image: linear-gradient(
    0deg,
    #ffffff80 77%,
    #f5f5f580 93%,
    #e1e1e180 100%
  );
  backdrop-filter: blur(15px);
  animation: ${(props) =>
    props.$animate === true
      ? props.$show === true
        ? css`
            ${heightIncreases} 0.3s ease-in-out forwards
          `
        : css`
            ${heightDecreases} 0.3s ease-in-out forwards
          `
      : "none"};
  padding: 0px 80px 0px 80px;
  overflow: hidden;
  box-sizing: border-box;

  ${media.phablet`
    width : 100%;
    padding : 0px 20px;
    `}
`;

const InnerContainer = styled.div`
  display: flex;
  width: 100%;
  max-width: 1440px;
  padding-top: 20px;
  box-sizing: border-box;
  gap: 80px;

  ${media.phablet`
    justify-content : space-between;
    gap : 0px;
    max-width : 480px;
    `}
`;

const HeaderItemColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 80px;
  gap: 20px;
`;

const OnlyMobileContainer = styled.div`
  display: none;
  ${media.phablet`
    display : flex;
    `}
`;
