import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
//import AWS, { CognitoIdentityCredentials } from "aws-sdk";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  ApolloLink,
  HttpLink,
  from,
} from "@apollo/client";
import config from "./aws-exports";
import { AUTH_TYPE, createAuthLink } from "aws-appsync-auth-link";
import { Amplify, Auth } from "aws-amplify";
import { onError } from "@apollo/client/link/error";

// const credentials = new CognitoIdentityCredentials({
//   IdentityPoolId: "ap-northeast-2:9e7544f7-e933-4c63-bcb6-11fd2624a10e",
// });

// const client = new AWS.Location({
//   credentials,
//   region: config.aws_appsync_region,
// });
//AWS.config.region ||

Amplify.configure(config);

const awsLink = ApolloLink.from([
  createAuthLink({
    url: config.aws_appsync_graphqlEndpoint,
    region: config.aws_appsync_region,
    auth: {
      type: AUTH_TYPE.AMAZON_COGNITO_USER_POOLS,
      jwtToken: async () =>
        (await Auth.currentSession()).getIdToken().getJwtToken(),
    },
  }),
  new HttpLink({ uri: config.aws_appsync_graphqlEndpoint }),
]);

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.log(
        `[GraphQL error]: Message: ${message}, Locations: ${locations}, Path: ${path}`
      );
    });
  }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`);
  }
});

const client = new ApolloClient({
  //link: from([errorLink, awsLink]),
  uri: config.aws_appsync_graphqlEndpoint,
  cache: new InMemoryCache(),
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
