import styled from "styled-components";
import GridPattern from "../../components/GridPattern";
import { useEffect, useState } from "react";
import Background from "../../assets/images/product_background.png";
import { Pretendard } from "../../assets/fonts";
import { ReactComponent as ToondaLogo } from "../../assets/icons/toondaNewLogo.svg";
import { ReactComponent as FeedLogo } from "../../assets/icons/feedNewLogo.svg";
import ToondaLaptop from "../../assets/images/toondaLaptop.png";
import FeedTablet from "../../assets/images/feedTablet.png";
import BottomBackground from "../../assets/images/product_bottom.png";
import * as COLORS from "../../assets/colors";
import TopBar from "../../components/TopBar";

export default function Product() {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const [isPC, setPC] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 1180) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [windowWidth]);

  return (
    <Container>
      <TopContainer isPC={isPC}>
        <BackgroundImage
          src={Background}
          width={isPC ? "1440px" : windowWidth > 640 ? "800px" : "360px"}
          height={isPC ? "847px" : windowWidth > 640 ? "551px" : "308px"}
        />
        <div
          style={{
            paddingTop: isPC ? "0px" : "156px",
            paddingBottom: isPC ? "0px" : "83px",
            zIndex: 1,
          }}
        >
          <Pretendard
            color={COLORS.OffWhite}
            size={isPC ? 24 : 16}
            weight={600}
            style={{
              lineHeight: isPC ? "44px" : "34px",
              letterSpacing: "-0.48px",
            }}
          >
            콘텐츠를 창작하기 위해서는 많은 노력이 필요하고,
            <br /> 종종 다른 사람들과 협업도 해야 하죠.
            <br /> 이러한 창작의 방식이 새로워지는 것이 콘파파가
            {!isPC && <br />} 꿈꾸는 미래입니다.
            <br /> 그리고 웹툰이 그러한 변화가 가장 필요한 영역이라
            {!isPC && <br />} 생각합니다.
          </Pretendard>
          <div style={{ paddingTop: isPC ? "93px" : "29px" }}>
            <Pretendard
              color={COLORS.OffWhite}
              size={isPC ? 120 : 43}
              weight={900}
              style={{ letterSpacing: "-0.863px" }}
            >
              TOONDA for All
            </Pretendard>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Pretendard
                color={COLORS.OffWhite}
                size={isPC ? 40 : 14}
                weight={700}
              >
                Designed for webtoon
              </Pretendard>
            </div>
          </div>
        </div>
      </TopContainer>
      <MiddleContainer>
        <MiddleContentContainer gap={isPC ? 126 : 40} isPC={isPC}>
          <img
            src={ToondaLaptop}
            width={isPC ? 669 : 335}
            height={isPC ? 410 : 204}
          />
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <Pretendard
              color={COLORS.Black}
              size={isPC ? 28 : 20}
              weight={700}
              style={{
                paddingBottom: "12px",
                width: isPC ? "340px" : "240px",
                letterSpacing: "-1.4px",
              }}
            >
              시나리오부터 콘티까지 빠르게
            </Pretendard>
            <Pretendard
              color={COLORS.Black}
              size={isPC ? 42 : 32}
              weight={800}
              style={{
                paddingBottom: "40px",
                width: isPC ? "340px" : "240px",
                textAlign: "start",
              }}
            >
              웹툰 콘티 툴
            </Pretendard>
            <ToondaLogo width={isPC ? 335 : 243} height={isPC ? 69 : 50} />
          </div>
        </MiddleContentContainer>
        <MiddleContentContainer gap={0} isPC={isPC}>
          {isPC === false && (
            <img
              src={FeedTablet}
              width={501}
              height={301}
              style={{ paddingRight: "50px" }}
            />
          )}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
              marginTop: isPC ? "0px" : "40px",
            }}
          >
            <Pretendard
              color={COLORS.Black}
              size={isPC ? 28 : 20}
              weight={700}
              style={{
                paddingBottom: "12px",
                width: isPC ? "420px" : "300px",
                letterSpacing: "-1.4px",
              }}
            >
              꼼꼼한 피드백 커뮤니케이션이 가능한
            </Pretendard>
            <Pretendard
              color={COLORS.Black}
              size={isPC ? 42 : 32}
              weight={800}
              style={{
                paddingBottom: "40px",
                width: isPC ? "420px" : "300px",
                textAlign: "start",
              }}
            >
              피드백 플랫폼
            </Pretendard>
            <div style={{ width: isPC ? "420px" : "300px" }}>
              <FeedLogo
                width={isPC ? 190 : 138}
                height={isPC ? 72 : 52}
                style={{ display: "flex", justifyContent: "left" }}
              />
            </div>
          </div>
          {isPC && <img src={FeedTablet} width={749} height={470} />}
        </MiddleContentContainer>
      </MiddleContainer>
      <BottomContainer isPC={isPC}>
        <Pretendard color={COLORS.OffWhite} size={isPC ? 48 : 18} weight={800}>
          TOONDA는 모든 웹툰 창작자들과 함께 합니다
        </Pretendard>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.div`
  overflow-x: hidden;
`;

interface TopContainerProps {
  isPC: boolean;
}

const TopContainer = styled.div<TopContainerProps>`
  width: 100%;
  height: ${(props) => (props.isPC ? 847 : 551)}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  background: black;
  overflow: hidden;
`;

const BackgroundImage = styled.img`
  position: absolute;
  top: 103px;
`;

const MiddleContainer = styled.div`
  height: 1442px;
  background: #ffffff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 123px;
`;

interface MiddleContentContainerProps {
  gap: number;
  isPC: boolean;
}

const MiddleContentContainer = styled.div<MiddleContentContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: ${(props) => props.gap}px;
  flex-direction: ${(props) => (props.isPC ? "row" : "column")};
`;

interface BottomContainerProps {
  isPC: boolean;
}

const BottomContainer = styled.div<BottomContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => (props.isPC ? 298 : 75)}px;
  background: url(${BottomBackground}) center/100% no-repeat;
`;
