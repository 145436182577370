import * as THREE from "three";

export class Slab {
  public read: THREE.WebGLRenderTarget;
  public write: THREE.WebGLRenderTarget;

  constructor(
    width: number,
    height: number,
    options?: THREE.RenderTargetOptions
  ) {
    this.read = new THREE.WebGLRenderTarget(width, height, options);
    this.write = this.read.clone();
  }

  public swap(): void {
    const tmp = this.read;
    this.read = this.write;
    this.write = tmp;
  }

  public static make(width: number, height: number): Slab {
    return new Slab(width, height, {
      wrapS: THREE.ClampToEdgeWrapping,
      wrapT: THREE.ClampToEdgeWrapping,
      magFilter: THREE.LinearFilter,
      minFilter: THREE.LinearFilter,
      format: THREE.RGBAFormat,
      type: THREE.FloatType,
      depthBuffer: false,
      stencilBuffer: false,
      generateMipmaps: false,
    });
  }
}
