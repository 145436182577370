import styled from "styled-components";
import { ChangeEvent, KeyboardEvent } from "react";
import { P13_500, Pretendard } from "../assets/fonts";

interface AuthInputProps {
  text: string;
  placeholder: string;
  type: string;
  description?: string;
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClickDescription?: () => void;
}

export default function AuthInput(props: AuthInputProps) {
  return (
    <InputContainer>
      <TitleContainer>
        <P13_500 color="#000000">{props.text}</P13_500>
        {props.description ? (
          <DescriptionContainer
            onClick={props.onClickDescription}
            enableClick={props.onClickDescription != null}
          >
            <P13_500 color="#747A75">{props.description}</P13_500>
          </DescriptionContainer>
        ) : (
          <></>
        )}
      </TitleContainer>
      <InputBox
        value={props.value}
        placeholder={props.placeholder}
        type={props.type}
        onChange={props.onChange}
      />
    </InputContainer>
  );
}

const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  ㅗㄷ
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
`;

const InputBox = styled.input`
  display: flex;
  height: 36px;
  padding: 0px 8px 0px 12px;
  align-items: center;
  gap: 440px;
  align-self: stretch;

  border: none;
  outline: none;
  border-radius: 2px;
  background: #eaefeb;

  color: #000000;
  font-family: Pretendard, sans-serif;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;

  &:placeholder {
    color: #747a75;
  }
`;

interface DescriptionContainerProps {
  enableClick?: boolean;
}
const DescriptionContainer = styled.div<DescriptionContainerProps>`
  display: flex;

  ${(props) =>
    props.enableClick === true &&
    `:hover {
    cursor: pointer;
  }`}
`;
