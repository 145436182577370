import React, { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as TypoText } from "../../../../assets/images/visual_feedback_platform_typo.svg";
import { ReactComponent as SuperEasy } from "../../../../assets/images/super-easy.svg";
import { ReactComponent as ReatLogo } from "../../../../assets/icons/reat.svg";
import SquareButton from "../../../../components/SquareButton";
import { media } from "../../../../components/media";

interface Props {
  width: number;
}

export default function Reat(props: Props) {
  const eventRef = React.useRef<HTMLDivElement>(null);
  const animateRef = React.useRef<HTMLDivElement>(null);
  const [eventHappened, setEventHappened] = React.useState(false);

  useEffect(() => {
    // use intersection observer to trigger animation
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          if (entry.isIntersecting) {
            setEventHappened(true);
          }
        },
        { threshold: 1.0 }
      );
    });

    if (eventRef.current) {
      observer.observe(eventRef.current);
    }

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Container id="reat">
      <TopContainer ref={animateRef} $visible={eventHappened}>
        <TitleContainer>
          <div className="inter fs-36 fw-500 phone:fs-18 tablet:fs-24">
            Connect with Clarity
          </div>
          <SEContainer>
            <SuperEasy style={{ width: "100%", height: "100%" }} />
          </SEContainer>
        </TitleContainer>
        <div
          className="times fs-96 phone:fs-32 tablet:fs-48 tablet:fs-60 fw-400"
          style={{
            whiteSpace: "nowrap",
            marginTop: props.width < 800 ? "-0.5em" : 0,
          }}
        >
          Visual Feedback Platform
        </div>
      </TopContainer>
      <ImageContainer ref={eventRef}>
        <img
          src={require("../../../../assets/images/reat_notebook.png")}
          alt=""
          width="100%"
        />
      </ImageContainer>
      <BodyContainer>
        <ContentContainer>
          <LogoContainer>
            <ReatLogo style={{ width: "100%", height: "100%" }} />
          </LogoContainer>
          <div className="inter fs-18 fw-300 phablet:fs-14 lh-1.6">
            reat is a cloud-based platform designed for visual feedback and
            collaborative workflow. It offers a central space where designers,
            advertisers, and creatives across various fields can give and
            receive real-time feedback on visual projects. With tools for
            version tracking, intuitive commenting, and project management, reat
            optimizes communication and accelerates project completion.
            <br />
            <br />
            While TOONDA is story-centric, reat casts a wider net, targeting
            diverse visual fields like design, advertising, and branding, making
            it a versatile solution for teams to work together seamlessly from
            anywhere. Both products embody CONPAPA’s commitment to reinventing
            the creative journey, yet each addresses distinct stages and types
            of visual work.
          </div>
        </ContentContainer>
        <SquareButton
          text="Learn More"
          bgcolor="#000000"
          textcolor="#FFFFFF"
          fontSize={24}
          mobileFontSize={18}
          onClick={() => {
            window.open("https://reat.one/hello", "_blank");
          }}
        />
      </BodyContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  ${media.phablet`
    padding : 80px 20px;
    `}
`;

const TitleContainer = styled.div`
  display: flex;

  align-items: center;
  gap: 16px;

  ${media.phablet`
    gap : 4px;
    `}
`;

const up = keyframes`
    from {
        opacity: 0;
        transform: translateY(300px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
    `;

const phablet_up = keyframes`
    from {
        opacity: 0;
        transform: translateY(300px);
    }
    60% {
        opacity : 0;
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
`;

const TopContainer = styled.div<{ $visible: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 16px;
  opacity: 0;

  animation: ${(props) =>
    props.$visible
      ? css`
          ${up} 1.0s ease-in-out forwards
        `
      : "none"};
  }
      ${media.phablet`
  animation: ${(props: any) =>
    props.$visible
      ? css`
          ${phablet_up} 0.7s ease-in-out forwards
        `
      : "none"};
        `}
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  z-index: 5;
  width: 100%;
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;

  padding: 80px;
  gap: 48px;

  ${media.phablet`
    padding: 60px 20px 80px 20px;
  `}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 140px;

  ${media.phablet`
    width : 100px;
    `}
`;

const SEContainer = styled.div`
  display: flex;

  ${media.phablet`
    height : 21px;`}
`;
