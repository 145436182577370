export const ConpapaLogo = ({
  lights: { light1, light2, light3, light4, light5, light6, light7, light8 },
}: {
  lights: {
    light1: boolean;
    light2: boolean;
    light3: boolean;
    light4: boolean;
    light5: boolean;
    light6: boolean;
    light7: boolean;
    light8: boolean;
  };
}) => (
  <svg
    width="1370"
    viewBox="0 0 1370 400"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    preserveAspectRatio="xMidYMid meet"
  >
    <defs>
      <linearGradient id="grad1" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor="rgb(255, 255, 255)" stopOpacity={1} />
        <stop offset="63%" stopColor="rgb(94, 69, 255, 255)" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad2" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor="rgb(255, 255, 255)" stopOpacity={1} />
        <stop offset="58%" stopColor="rgba(255, 88, 0, 255)" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad3" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="-1%" stopColor="#CC81FF" stopOpacity={1} />
        <stop offset="35%" stopColor="#C979FF" stopOpacity={1} />
        <stop offset="75%" stopColor="#9E6CC0" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad4" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="22%" stopColor="#FFE0A4" stopOpacity={1} />
        <stop offset="100%" stopColor="#C78607" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad5" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor="#FFFFFF" stopOpacity={1} />
        <stop offset="51%" stopColor="#00CC76" stopOpacity={1} />
        <stop offset="100%" stopColor="#00663B" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad6" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor="#FF8888" stopOpacity={1} />
        <stop offset="51%" stopColor="#A46B6B" stopOpacity={1} />
        <stop offset="100%" stopColor="#3E2828" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad7" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor="#FFFAE9" stopOpacity={1} />
        <stop offset="84%" stopColor="#FFC918" stopOpacity={1} />
        <stop offset="100%" stopColor="#D7A80C" stopOpacity={1} />
      </linearGradient>
      <linearGradient id="grad8" x1="50%" y1="0%" x2="50%" y2="100%">
        <stop offset="0%" stopColor="#9CD6FF" stopOpacity={1} />
        <stop offset="52%" stopColor="#2DA7FF" stopOpacity={1} />
        <stop offset="100%" stopColor="#1B6499" stopOpacity={1} />
      </linearGradient>
    </defs>
    <g transform="translate(25, 0)">
      <polygon points="0 0 1370 0 1370 400 0 400" fill="white" />
      <path
        d="M385.577 208.735L449.416 63.5094L501.136 59.0351C485.416 86.3853 469.855 116.615 455.935 145.059L456.874 145.882C462.676 136.856 469.191 127.147 476.44 116.755C483.677 106.366 491.508 96.7231 499.929 87.8327C508.35 78.9501 517.252 71.4917 526.631 65.473C536.018 59.462 545.762 56.4468 555.887 56.4468C566.011 56.4468 575.352 59.1088 583.097 64.4446C590.847 69.7765 595.515 77.9218 597.102 88.8611C598.414 97.8873 596.958 107.189 592.74 116.755C588.526 126.328 583.66 135.696 578.157 144.854C572.647 154.02 567.695 162.774 563.299 171.113C558.906 179.457 557.159 186.771 558.075 193.062C558.471 195.798 559.569 198.188 561.374 200.245C563.17 202.294 565.72 203.322 569.003 203.322C575.014 203.322 581.448 200.516 588.293 194.913C595.135 189.309 602.069 182.398 609.081 174.195C614.46 167.904 619.761 161.137 625.003 153.884C630.242 146.643 635.104 139.669 639.606 132.964C644.103 126.266 648.128 120.111 651.698 114.5C655.256 108.896 658.027 104.449 660.014 101.162L664.003 103.219C656.494 115.528 648.419 128.241 639.8 141.373C631.173 154.497 622.326 166.538 613.249 177.47C603.703 188.956 594.13 197.92 584.537 204.343C574.94 210.769 564.668 213.986 553.733 213.986C541.424 213.986 531.668 210.354 524.462 203.113C517.256 195.868 512.82 186.5 511.152 175.013C509.724 165.168 511.031 155.801 515.075 146.907C519.118 138.024 523.853 129.681 529.285 121.885C534.722 114.089 539.627 106.913 544.008 100.344C548.389 93.7816 550.167 87.6271 549.332 81.8877C548.223 74.2353 543.969 70.4013 536.585 70.4013C531.664 70.4013 526.006 72.9275 519.638 77.9916C513.255 83.0519 506.565 89.6799 499.553 97.8873C492.54 106.091 485.52 115.257 478.52 125.373C471.508 135.49 464.973 145.614 458.923 155.731C452.869 165.855 447.689 175.421 443.401 184.447C439.101 193.473 436.005 200.858 434.103 206.601L385.584 208.743L385.577 208.735Z"
        fill="black"
      />
      <path
        d="M208.297 146.282C198.056 182.107 170.349 214.273 107.74 214.273C35.965 214.269 0 164.443 0 107.41C0 50.3774 39.7214 0 110.041 0C171.827 0 202.1 34.0247 208.759 68.1892H164.928C159.2 53.2762 146.348 34.0442 109.548 34.0442C68.294 34.0442 45.5539 65.1119 45.5539 105.807C45.5539 146.503 67.0444 180.423 109.835 180.423C142.118 180.423 155.816 167.369 164.835 146.278H208.293L208.297 146.282Z"
        fill="black"
      />
      <path
        d="M389.942 135.11C389.942 179.674 359.72 213.982 304.829 213.982C249.939 213.982 222.43 176.581 222.43 135.199C222.43 93.8166 252.031 56.4468 306.607 56.4468C361.183 56.4468 389.946 97.2858 389.946 135.11H389.942ZM264.402 135.199C264.402 160.042 278.946 182.32 306.23 182.32C333.515 182.32 347.562 160.826 347.562 135.816C347.562 110.806 334.186 88.1083 305.761 88.1083C277.336 88.1083 264.402 109.983 264.402 135.203V135.199Z"
        fill="black"
      />
      <path
        d="M671.729 192.984V273.665H630.545V97.3905C630.545 85.1707 630.545 76.1716 630.254 63.9479L671.729 60.5175V81.7791C680.255 68.4843 696.138 56.4429 725.498 56.4429C764.641 56.4429 795.465 88.8339 795.465 132.37C795.465 183.364 759.309 213.978 719.883 213.978C693.262 213.978 679.149 203.248 671.729 192.98V192.984ZM753.085 134.5C753.085 111.085 739.759 89.0085 712.448 89.0085C682.692 89.0085 670.736 108.784 670.736 135.575C670.736 162.367 684.698 181.009 711.866 181.009C739.033 181.009 753.085 161.001 753.085 134.496V134.5Z"
        fill="black"
      />
      <path
        d="M959.255 169.588V209.903H919.782V191.463C911.811 205.192 894.698 213.978 865.485 213.978C824.188 213.978 808.006 188.859 808.006 165.463C808.006 126.549 842.458 115.753 886.056 115.753H918.963V110.026C918.963 96.5873 913.146 85.0736 888.113 85.0736C865.105 85.0736 859.901 94.29 857.631 104.469H816.447C817.875 85.6092 830.968 56.4429 887.585 56.4429C949.131 56.4429 959.255 85.7295 959.255 110.615V169.588ZM918.967 142.487C915.16 142.281 904.943 142.281 886.848 142.281C859.598 142.281 848.058 149.002 848.058 163.364C848.058 174.703 856.901 184.04 876.44 184.04C906.685 184.04 918.967 168.607 918.967 146.119V142.49V142.487Z"
        fill="black"
      />
      <path
        d="M1028.57 192.984V273.665H987.385V97.3905C987.385 85.1707 987.385 76.1716 987.094 63.9479L1028.57 60.5175V81.7791C1037.1 68.4843 1052.98 56.4429 1082.34 56.4429C1121.48 56.4429 1152.3 88.8339 1152.3 132.37C1152.3 183.364 1116.15 213.978 1076.72 213.978C1050.1 213.978 1035.99 203.248 1028.57 192.98V192.984ZM1109.93 134.5C1109.93 111.085 1096.6 89.0085 1069.29 89.0085C1039.53 89.0085 1027.58 108.784 1027.58 135.575C1027.58 162.367 1041.54 181.009 1068.71 181.009C1095.87 181.009 1109.93 161.001 1109.93 134.496V134.5Z"
        fill="black"
      />
      <path
        d="M1314.68 169.588C1314.68 187.466 1319.62 206.617 1320 209.903H1279.44C1278.28 205.744 1275.42 195.883 1275.21 191.463C1267.24 205.192 1250.13 213.978 1220.92 213.978C1179.62 213.978 1163.44 188.859 1163.44 165.463C1163.44 126.549 1197.89 115.753 1241.49 115.753H1274.4V110.026C1274.4 96.5873 1268.58 85.0736 1243.55 85.0736C1220.54 85.0736 1215.33 94.29 1213.06 104.469H1171.88C1173.31 85.6092 1186.4 56.4429 1243.02 56.4429C1304.56 56.4429 1314.69 85.7295 1314.69 110.615V169.588H1314.68ZM1274.4 142.487C1270.59 142.281 1260.37 142.281 1242.28 142.281C1215.03 142.281 1203.49 149.002 1203.49 163.364C1203.49 174.703 1212.33 184.04 1231.87 184.04C1262.11 184.04 1274.4 168.607 1274.4 146.119V142.49V142.487Z"
        fill="black"
      />
      {light8 ? (
        <polygon
          points="900 0 1330 0 1280 400 1200 400"
          fill="url(#grad8)"
          style={{ mixBlendMode: "hard-light" }}
        >
          <animate
            attributeName="points"
            dur="1s"
            fill="freeze"
            from="1200 0 1280 0 1280 0 1200 0"
            to="900 0 1330 0 1280 400 1200 400"
          />
        </polygon>
      ) : null}
      {light6 ? (
        <polygon
          points="380 0 750 0 930 400 880 400"
          fill="url(#grad6)"
          style={{ mixBlendMode: "hard-light" }}
        />
      ) : null}
      {light4 ? (
        <polygon
          points="150 30 550 30 600 400 550 400"
          fill="url(#grad4)"
          style={{ mixBlendMode: "hard-light" }}
        />
      ) : null}
      {light3 ? (
        <polygon
          points="440 0 1250 0 470 400 400 400"
          fill="url(#grad3)"
          style={{ mixBlendMode: "hard-light" }}
        />
      ) : null}
      {light2 ? (
        <polygon
          points="120 0 430 0 300 400 240 400"
          fill="url(#grad2)"
          style={{ mixBlendMode: "hard-light" }}
        />
      ) : null}
      {light7 ? (
        <polygon
          points="800 60 1300 60 1100 400 1030 400"
          fill="url(#grad7)"
          style={{ mixBlendMode: "multiply" }}
        />
      ) : null}
      {light5 ? (
        <polygon
          points="620 60 1050 60 780 400 690 400"
          fill="url(#grad5)"
          style={{ mixBlendMode: "multiply" }}
        />
      ) : null}
      {light1 ? (
        <polygon
          points="-30 30 230 30 140 400 50 400"
          fill="url(#grad1)"
          style={{ mixBlendMode: "multiply" }}
        />
      ) : null}
    </g>
  </svg>
);
