import React from "react";
import styled from "styled-components";
import { ReactComponent as Pacman } from "../../../assets/icons/Pacman.svg";
import { media } from "../../../components/media";

function ThreeDots({ width }: { width: number }) {
  return (
    <svg viewBox="0 0 100 40" width={`${width}`}>
      <circle cx="10" cy="20" r="10" fill="black" />
      <circle cx="50" cy="20" r="10" fill="black" />
      <circle cx="90" cy="20" r="10" fill="black" />
    </svg>
  );
}

export function CareerSection() {
  return (
    <Container id="careers">
      <TitleContainer>
        <div className="inter fs-36 fw-700 phablet:fs-24 tablet:fs-32">
          Careers at CONPAPA
        </div>
      </TitleContainer>
      <BodyContainer>
        <BodyTitleContainer>
          <div className="inter fs-54 fw-700 phablet:fs-32 tablet:fs-36">
            Join Us If You Are One of Our Kind
          </div>
        </BodyTitleContainer>
        <BodyContentContainer>
          <div className="inter fs-18 fw-300 phablet:fs-14 lh-1.7">
            We’re looking for individuals who resonate with the journey of
            creativity and are excited to illuminate their unique perspectives
            as we harmoniously create impact together. We seek teammates who are
            passionate about uncovering challenges, formulating ideas, and
            crafting innovative solutions through hands-on experimentation. When
            outcomes diverge from expectations, they view it as an
            opportunity—engaging in constructive feedback to push boundaries and
            foster collective growth
          </div>
        </BodyContentContainer>
      </BodyContainer>
      <PacmanContainer>
        <div className="inter fs-36 fw-700 phablet:fs-24">Let's talk</div>
        <PacmanInnerContainer>
          <Pacman style={{ height: "100%" }} />
        </PacmanInnerContainer>
        <ThreeDots width={20} />
      </PacmanContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: url(${require("../../../assets/images/career-background.png")});
  background-size: cover;
  padding: 120px 80px;
  gap: 40px;
  box-sizing: border-box;
  ${media.tablet`
    padding : 120px 40px;
    `}

  ${media.phablet`
    padding : 80px 20px;
    width : 100%;
    background-size : crop;`}
`;

const TitleContainer = styled.div`
  display: flex;
  background: white;
  width: fit-content;
  padding: 4px 200px 4px 16px;
  box-sizing: border-box;

  ${media.tablet`
    padding : 4px 100px 4px 16px;`}

  ${media.phablet`
    padding :8px 16px;`}
`;

const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyTitleContainer = styled.div`
  display: flex;
  width: fit-content;
  padding: 16px;
  background: white;
  box-sizing: border-box;

  ${media.phablet`
    margin-right: 40px;`}
`;

const BodyContentContainer = styled.div`
  display: flex;

  padding: 16px;
  background: white;
  box-sizing: border-box;
  max-width: 700px;
  width: fit-content;

  ${media.phablet`
    width : 100%;
    padding : 16px 20px;
    `}
`;

const PacmanContainer = styled.div`
  display: flex;
  background: white;
  align-items: center;
  gap: 8px;
  width: fit-content;
  padding: 4px 48px 4px 16px;
`;

const PacmanInnerContainer = styled.div`
  display: flex;
  height: 24px;
`;
