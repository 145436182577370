import { ReactComponent as Logo } from "../../assets/icons/ConpapaLogo.svg";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import styled from "styled-components";
import { ReactComponent as Hamburger } from "../../assets/icons/Hamburger.svg";
import { P16_600 } from "../../assets/fonts";
import MobileHeaderMenu from "./MobileHeaderMenu";
import MobileMenuButton from "./MobileMenuButton";

import { ReactComponent as GridMenu } from "../../assets/icons/GridMenu.svg";
import HeaderButton from "./HeaderButton";
import HeaderMenu from "./HeaderMenu";
import { media } from "../media";

export default function Header() {
  const [prevScrollPos, setPrevScrollPos] = useState(0);
  const [visible, setVisible] = useState(true);
  const navigate = useNavigate();
  const [mouseEnter, setMouseEnter] = useState(false);

  const [eventProcessing, setEventProcessing] = useState(false);
  const mouseEnterEventHappenedRef = React.useRef(false);

  function handleEnter() {
    if (windowWidth < 800) return;

    setMouseEnter(true);
  }

  function handleLeave() {
    if (windowWidth < 800) return;

    setMouseEnter(false);
  }

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 부드럽게 스크롤
    });
  };

  const handleScroll = () => {
    const currentScrollPos = window.pageYOffset;

    if (enableHideHeaderRef.current) return;

    // 스크롤 방향 확인
    if (currentScrollPos < 10 || prevScrollPos > currentScrollPos) {
      setVisible(true); // 스크롤을 올릴 때 Header 표시
    } else {
      setVisible(false); // 스크롤을 내릴 때 Header 숨김
      setMouseEnter(false);
    }

    setPrevScrollPos(currentScrollPos);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [prevScrollPos]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [menuVisible, setMenuVisible] = useState(false);

  function handleMenuVisibility() {
    setMenuVisible(!menuVisible);
  }

  const location = useLocation();

  const enableHideHeaderRef = React.useRef(false);

  useEffect(() => {
    if (windowWidth < 800) {
      setMouseEnter(false);
    }

    // get hash from location
    const hash = location.hash;

    // if hash exists, scroll to the element
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        // give top margin about 80px
        const y =
          element.getBoundingClientRect().top +
          window.scrollY -
          (windowWidth > 800 ? 160 : 80);
        enableHideHeaderRef.current = true;
        setTimeout(() => {
          window.scrollTo({ top: y });
        }, 100);

        // hide insideheader
        if (
          windowWidth < 800 ||
          (hash.includes("company") === false &&
            hash.includes("products") === false &&
            hash.includes("contact") === false)
        ) {
          setMouseEnter(false);
        }

        // disable hide header after 0.2 second
        setTimeout(() => {
          enableHideHeaderRef.current = false;
        }, 500);
      }
    }
  }, [location, windowWidth]);

  useEffect(() => {
    window.scrollTo({ top: 0 });
  }, [location]);

  if (
    location.pathname.includes("/openalarm") ||
    location.pathname.includes("/openAlarm")
  ) {
    return null;
  }

  return (
    <Container $visible={visible} onMouseLeave={handleLeave}>
      <InnerContainer onMouseEnter={handleEnter}>
        <InnerInnerContainer>
          <Logo
            width="117.287px"
            height="24.316px"
            cursor="pointer"
            onClick={() => {
              navigate("/");
              scrollToTop();
            }}
            style={{ marginRight: 20 }}
          />
          <HeaderButton
            width={80}
            text="Company"
            onClick={() => navigate("/company")}
          />
          <HeaderButton
            width={80}
            text="Products"
            onClick={() => {
              navigate("/#products");
            }}
          />
          <HeaderButton
            width={80}
            text="Contact"
            onClick={() => {
              navigate("/contact");
            }}
          />
        </InnerInnerContainer>
        <MobileInnerContainer>
          <MobileInnerInnerContainer>
            <Logo
              width="117.287px"
              height="24.316px"
              cursor="pointer"
              onClick={() => {
                navigate("/");
                scrollToTop();
              }}
            />
            <IconContainer onClick={() => setMouseEnter((prev) => !prev)}>
              <Hamburger />
            </IconContainer>
          </MobileInnerInnerContainer>
        </MobileInnerContainer>
      </InnerContainer>
      {visible === true ? <HeaderMenu show={mouseEnter} /> : null}
    </Container>
  );
}

interface ContainerProps {
  $visible: boolean;
}

const Container = styled.div<ContainerProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: auto;

  width: 100%;
  max-width: 100%;

  position: fixed;
  top: ${(props) => (props.$visible ? "0" : "-278px")};
  left: 0;
  right: 0;
  z-index: 1000;
  transition: top 0.3s ease-in-out;
`;

const InnerContainer = styled.div`
  display: flex;

  box-sizing: border-box;
  height: 48px;
  padding: 0px 80px;

  background: white;
  width: 100%;

  justify-content: center;
  align-items: center;

  ${media.phablet`
    padding : 0px;
    // height : 82px;
   // align-items : flex-end;
     align-items : center;
  `}
`;

const InnerInnerContainer = styled.div`
  display: flex;
  gap: 80px;

  max-width: 1440px;
  width: 100%;

  align-items: center;

  ${media.phablet`
    display : none;
  `}
`;

const MobileInnerContainer = styled.div`
  display: none;
  ${media.phablet`
    display : flex;
    width : 100%;

    padding : 16px;
  `}
`;

const MobileInnerInnerContainer = styled.div`
  display: flex;

  width: 100%;
  justify-content: space-between;
  align-items: flex-end;
`;

const IconContainer = styled.div`
  display: flex;
  cursor: pointer;

  padding: 5px;
  margin: -5px;
`;
