import React from "react";
import styled from "styled-components";

interface Props {
  text: string;
  width?: number;
  onClick?: () => void;
  bold?: boolean;
}

export default function InsideHeaderButton(props: Props) {
  return (
    <Container $width={props.width} $bold={props.bold} onClick={props.onClick}>
      {props.text}
    </Container>
  );
}

const Container = styled.div.attrs<{
  $width: number | undefined;
  $bold: boolean | undefined;
}>((props) => ({
  style: {
    width: props.$width ? `${props.$width}px` : "auto",
    fontWeight: props.$bold ? 700 : undefined,
  },
}))`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  // disable line break
  white-space: nowrap;

  /* Our Culture Our Structure */

  font-family: "Inter", sans-serif;
  font-style: normal;
  font-size: 14px;
  line-height: 20px;
  /* or 200% */
  letter-spacing: -0.04em;

  &:hover {
    cursor: pointer;
    font-weight: 600;
  }

  transition: font-weight 0.3s;
`;
