import React from "react";
import { ReactComponent as LongArrow } from "../../../assets/icons/long-arrow.svg";

import styled from "styled-components";
import { media } from "../../../components/media";

export default function SecondPage() {
  return (
    <Container>
      <InnerContainer>
        <LeftContainer>
          <ImageContainer
            src={require("../../../assets/images/pan-image.png")}
            alt=""
          />
        </LeftContainer>
        <RightContainer>
          <span className="inter fs-16 fw-300 phone:lh-1.5 lh-2">
            At CONPAPA, we view creation as an intricate journey filled with the
            complex emotions and experiences of creators—doubt, joy,
            frustration, inspiration, and fulfillment—as raw ideas evolve into
            impactful content. This process, where countless ideas collide and
            refine, embodies the true challenges of creation. We are here to
            accompany creators on their journey, helping them nurture their
            original visions into meaningful final works.
            <br />
            <br />
            Furthermore, we aim to create transformative changes along that
            journey.
          </span>
        </RightContainer>
      </InnerContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;

  box-sizing: border-box;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
  padding: 0px 20px 0px 80px;

  ${media.tablet`
   padding : 0px 20px;
    `}

  ${media.phablet`
    padding : 0px 20px 60px 20px;`}
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 2;

  ${media.tablet`
      padding : 40px;
      `}

  ${media.phone`
      padding : 20px;`}
`;

const ImageContainer = styled.img`
  object-fit: cover;
  object-position: top left;
  width: 100%;

  ${media.tablet`
    width : 100%;
    height : 100%;
    padding-top : 80px;
    `}
`;

const InnerContainer = styled.div`
  display: flex;
  gap: 40px;
  ${media.tablet`
    flex-direction: column;
    gap : 0px;
    `}
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  box-sizing: border-box;
  min-width: 340px;
  padding: 0px 40px 0px 0px;
  align-items: flex-start;
  justify-content: flex-start;

  ${media.tablet`
    padding: 0px 20px;
    line-height: 1.77;
    `}
`;
