// Gray
export const Gray900 = "#313532";
export const Gray700 = "#444845";
export const Gray500 = "#747A75";
export const Gray300 = "#BAC3BC";
export const Gray100 = "#EAEFEB";

export const Grays = [Gray900, Gray700, Gray500, Gray300, Gray100];

export const Black = "#000000";
export const NearBlack = "#1A1C1B";
export const OffWhite = "#FFFFFF";
export const Ivory = "#F2ECE8";
export const Ivory100 = "#D9D9D9";
export const Orange = "#FF5800";
export const Orange100 = "#E1D1C9";
export const OrangeDark = "#C54400";
export const Red = "#E11919";

export const Background = "#E4DEDB";

export const Tag_Blue = "#E1EAF1";
export const Tag_Pink = "#F1E1E9";
export const Tag_Yellow = "#FAF3D9";
export const Tag_Red = "#FAE0E0";
export const Tag_Green = "#E5F1E1";
export const Tag_Gray = "#E3E3E3";
export const Tag_Brown = "#F0E2D4";
export const Tag_Turquis = "#DEF1F1";
export const Tag_Purple = "#EFE1F8";

export const Shadow_Box = "#00000040";

export const Overlay30 = "#0000004D";
export const Overlay10 = "#0000001A";

export const White60Pct = "#FFFFFF99";

export const TagColors = [
  Tag_Blue,
  Tag_Pink,
  Tag_Yellow,
  Tag_Red,
  Tag_Green,
  Tag_Gray,
  Tag_Brown,
  Tag_Turquis,
  Tag_Purple,
];

export const ToonBlue = "#5E45FF";
export const ToonBlue100 = "#4E448A";

export const LightTextColorTitle = "#11142D";
