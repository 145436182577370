import React from "react";
import { LongArrow } from "../../../assets/icons/LongArrow";
import { ReactComponent as ShortArrow } from "../../../assets/icons/ShortArrow.svg";

import styled from "styled-components";
import { media } from "../../../components/media";

interface Props {
  width: number;
}
export default function FirstPage(props: Props) {
  return (
    <Container>
      <TopContainer>
        <div className="inter fs-36 fw-300 phablet:fs-24">
          <i>Formless</i>
        </div>
        <ArrowContainer>
          {props.width >= 1050 ? <LongArrow /> : <ShortArrow />}
        </ArrowContainer>
        <div className="shrik fs-36 fw-400 phablet:fs-24">Forming</div>
        <ArrowContainer>
          {props.width >= 1050 ? <LongArrow /> : <ShortArrow />}
        </ArrowContainer>
        <div className="inter fs-36 fw-300 phablet:fs-24">
          <i>Formed</i>
        </div>
      </TopContainer>
      <BottomContainer>
        <div
          className="inter fs-28 fw-400 phablet:fs-18"
          style={{
            lineHeight: 1.77,
          }}
        >
          We recognize the <span className="shrik">Forming</span> stage as the
          most valuable phase of creativity and aim to drive transformative
          change within it.
        </div>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;

  width: 100%;
  height: 100%;

  gap: 80px;

  padding: 160px 0px;
`;

const ArrowContainer = styled.div`
  ${media.tablet`
    display : flex;
    height : 40px;
    align-items : center;
  `}
`;

const TopContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 40px;

  ${media.tablet`
    flex-direction: column;
    gap : 10px;
    `}
`;

const BottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;

  max-width: 800px;

  ${media.tablet`
    padding : 0 48px;
    `}
`;

const DescriptionContainer = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 28px;
  font-weight: 400;
  line-height: 45px;
  letter-spacing: -0.04em;
  text-align: center;
`;
