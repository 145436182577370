export const displayscalar_fs = `
uniform sampler2D read;

uniform vec3 bias;
uniform vec3 scale;

varying vec2 texCoord;

void main()
{
    vec4 original = vec4(bias + scale * texture2D(read, texCoord).xxx, 1.0);
    // reverse the color to white
    gl_FragColor = vec4(1.0 - original.xyz, 1.0);

}
`;
