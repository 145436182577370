import { FileLoader } from "./FileLoader";
import { Mouse } from "./Mouse";
import { Slab } from "./Slab";
import { Solver } from "./Solver";
import { Display } from "./Display";

export const F2D = {
  Mouse,
  FileLoader,
  Slab,
  Solver,
  Display,
};
