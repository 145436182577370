import React from "react";
import styled from "styled-components";
import SquareButton from "../../../components/SquareButton";
import { media } from "../../../components/media";
import { useNavigate } from "react-router-dom";

export default function ReactOutSection() {
  const navigate = useNavigate();
  return (
    <Container>
      <div className="inter fs-36 fw-700 phablet:fs-20">
        Feel free to reach out anytime!
      </div>
      <SquareButton
        text="Contact Us"
        bgcolor="black"
        textcolor="white"
        fontSize={24}
        mobileFontSize={18}
        onClick={() => {
          navigate("/contact#business");
        }}
      />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 80px;
  padding: 320px 0px;
  ${media.phablet`
    gap : 30px;
    padding : 160px 20px;`}
`;
