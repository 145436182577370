import { vorticity_fs } from "../shaders/vorticity";
import { vorticityforce_fs } from "../shaders/vorticityforce";
import { boundary_fs } from "../shaders/boundary";
import { advect_fs } from "../shaders/advect";
import { basic_vs } from "../shaders/basic";
import { gradient_fs } from "../shaders/gradient";
import { jacobiscalar_fs } from "../shaders/jacobiscalar";
import { jacobivector_fs } from "../shaders/jacobivector";
import { displayscalar_fs } from "../shaders/displayscalar";
import { displayvector_fs } from "../shaders/displayvector";
import { divergence_fs } from "../shaders/divergence";
import { splat_fs } from "../shaders/splat";

export interface File {
  name: string;
  url: string;
  text?: string; // Optional property for the loaded text
}

export class FileLoader {
  private path: string;
  private queue: File[];

  constructor(path: string, names: string[]) {
    this.path = path;
    this.queue = names.map((name) => ({
      name,
      url: `src/pages/Home/${path}/${name}`,
    }));
  }

  // Load all files currently in the queue, calls onDone when all files have been downloaded.
  public run(onDone: (files: { [key: string]: string }) => void): void {
    const files: { [key: string]: string } = {};
    let filesRemaining = this.queue.length;

    const fileLoaded = (file: File): void => {
      // get text from file
      let text = "";
      switch (file.name) {
        case "vorticity.fs":
          text = vorticity_fs;
          break;
        case "vorticityforce.fs":
          text = vorticityforce_fs;
          break;
        case "boundary.fs":
          text = boundary_fs;
          break;
        case "advect.fs":
          text = advect_fs;
          break;
        case "basic.vs":
          text = basic_vs;
          break;
        case "gradient.fs":
          text = gradient_fs;
          break;
        case "jacobiscalar.fs":
          text = jacobiscalar_fs;
          break;
        case "jacobivector.fs":
          text = jacobivector_fs;
          break;
        case "displayscalar.fs":
          text = displayscalar_fs;
          break;
        case "displayvector.fs":
          text = displayvector_fs;
          break;
        case "divergence.fs":
          text = divergence_fs;
          break;
        case "splat.fs":
          text = splat_fs;
          break;
        default:
          text = file.text || "";
      }

      files[file.name] = text || ""; // Ensure text is defined
      filesRemaining--;
      if (filesRemaining === 0) {
        onDone(files);
      }
    };

    const loadFile = (file: File): void => {
      fileLoaded(file);
      //   const request = new XMLHttpRequest();
      //   request.onload = (): void => {
      //     if (request.status === 200) {
      //       file.text = request.responseText;
      //     }
      //     fileLoaded(file);
      //   };
      //   request.open("GET", file.url, true);
      //   request.send();
    };

    for (const file of this.queue) {
      loadFile(file);
    }
    this.queue = [];
  }
}
