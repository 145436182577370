import React from "react";

import styled from "styled-components";

interface Props {
  logo: React.ReactNode;
}
export default function LogoItem(props: Props) {
  return <Container>{props.logo}</Container>;
}

const Container = styled.div`
  display: flex;
  width: 70px;
  height: 70px;
  align-items: center;
  justify-content: center;
`;
