import React, { useEffect } from "react";

import styled, { keyframes } from "styled-components";
import ImpactCard from "./ImpactCard";
import TextAnimation from "./TextAnimation";
import { media } from "../../../components/media";
import { ReactComponent as RightArrow } from "../../../assets/icons/page-arrow-right.svg";
import { ReactComponent as LeftArrow } from "../../../assets/icons/page-arrow-left.svg";

interface Props {
  windowWidth: number;
}

export default function ImpactSection(props: Props) {
  const [hoveredCardIndex, setHoveredCardIndex] = React.useState<number | null>(
    null
  );
  const [currentXPos, setCurrentXPos] = React.useState<number>(0);

  const handleHover = (index: number | null) => {
    setHoveredCardIndex(index);
  };

  const [pageIndex, setPageIndex] = React.useState<number>(0);
  function handleClickRight() {
    setPageIndex((prev) => prev + 1);
  }
  function handleClickLeft() {
    setPageIndex((prev) => prev - 1);
    setHoveredCardIndex(pageIndex - 1);
  }

  useEffect(() => {
    if (pageIndex === 0) {
      setCurrentXPos(0);
    } else if (pageIndex === 1) {
      setCurrentXPos(props.windowWidth);
    } else if (pageIndex === 2) {
      setCurrentXPos(props.windowWidth * 2);
    }
  }, [props.windowWidth, pageIndex]);

  const touchXRef = React.useRef<number | null>(null);

  function handleTouchStart(e: React.TouchEvent) {
    if (props.windowWidth >= 800) return;
    touchXRef.current = e.touches[0].clientX;

    setHoveredCardIndex(pageIndex);
  }

  function handleTouchMove(e: React.TouchEvent) {
    if (props.windowWidth >= 800) return;
    if (touchXRef.current == null) return;

    const diff = touchXRef.current - e.touches[0].clientX;

    if (diff > 60) {
      setPageIndex((prev) => {
        const newPage = prev + 1;
        if (newPage > 2) return 2;
        else return newPage;
      });
      touchXRef.current = null;
    } else if (diff < -60) {
      setPageIndex((prev) => {
        const newPage = prev - 1;
        if (newPage < 0) return 0;
        else return newPage;
      });
      touchXRef.current = null;
    }
  }

  function handleTouchEnd() {
    if (props.windowWidth >= 800) return;
    touchXRef.current = null;
  }

  useEffect(() => {
    function handleMouseClick(e: MouseEvent) {
      // check e.target is not a child of the mainRef
      if (e.target instanceof Node && !mainRef.current?.contains(e.target)) {
        setHoveredCardIndex(null);
      }
    }

    window.addEventListener("click", handleMouseClick);

    return () => {
      window.removeEventListener("click", handleMouseClick);
    };
  }, []);

  const mainRef = React.useRef<HTMLDivElement>(null);
  return (
    <Container ref={mainRef}>
      <TopContainer>
        <div
          className="inter fs-24 fw-600 tablet:fs-18"
          style={{ color: "white" }}
        >
          The Impact We Envision
        </div>
      </TopContainer>
      <div style={{ height: 60 }} />
      <OuterBodyContainer
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
      >
        <BodyContainer $translateX={currentXPos}>
          <ImpactCard
            text="Creation Reinvented"
            onHover={() => handleHover(0)}
            cardIndex={0}
            selected={hoveredCardIndex === 0}
          />
          <ImpactCard
            text="Collaboration Redefined"
            onHover={() => handleHover(1)}
            cardIndex={1}
            selected={hoveredCardIndex === 1}
          />
          <ImpactCard
            text="Scalability Reimagined"
            onHover={() => handleHover(2)}
            cardIndex={2}
            selected={hoveredCardIndex === 2}
          />
        </BodyContainer>
        {pageIndex !== 0 ? (
          <LeftArrowContainer onClick={handleClickLeft}>
            <ArrowContainer>
              <LeftArrow />
            </ArrowContainer>
          </LeftArrowContainer>
        ) : null}
        {pageIndex !== 2 ? (
          <RightArrowContainer onClick={handleClickRight}>
            <ArrowContainer>
              <RightArrow />
            </ArrowContainer>
          </RightArrowContainer>
        ) : null}
        <MoreContainer>
          <div
            style={{ padding: 5, margin: -5, cursor: "pointer" }}
            onClick={() => setPageIndex(0)}
          >
            <Dot $color={pageIndex === 0 ? "#aaaaaa" : "#2f2f2f"} />
          </div>
          <div
            style={{ padding: 5, margin: -5, cursor: "pointer" }}
            onClick={() => setPageIndex(1)}
          >
            <Dot $color={pageIndex === 1 ? "#aaaaaa" : "#2f2f2f"} />
          </div>
          <div
            style={{ padding: 5, margin: -5, cursor: "pointer" }}
            onClick={() => setPageIndex(2)}
          >
            <Dot $color={pageIndex === 2 ? "#aaaaaa" : "#2f2f2f"} />
          </div>
        </MoreContainer>
      </OuterBodyContainer>
      <div style={{ height: 50 }} />
      <OuterInputContainer>
        <InputContainer>
          {hoveredCardIndex == null ||
          (props.windowWidth < 799 && hoveredCardIndex !== pageIndex) ? (
            <>
              <Carat />
              <div
                className="inter fs-24 fw-400 phablet:fs-16 tablet:fs-20"
                style={{ color: "#909090" }}
              >
                What possibilities could unfold if creativity met innovation at
                every step?
              </div>
            </>
          ) : hoveredCardIndex === 0 ? (
            <TextAnimation text="Driving innovation across technology and experiences, helping creators realize their ideas."></TextAnimation>
          ) : hoveredCardIndex === 1 ? (
            <TextAnimation text="Expending collaboration beyond teams—partnering seamlessly with AI agents and even yourself."></TextAnimation>
          ) : hoveredCardIndex === 2 ? (
            <TextAnimation text="Enabling the easy transformation of stories and visuals into new content while protecting copyright."></TextAnimation>
          ) : null}
        </InputContainer>
      </OuterInputContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 0px 0px 320px 0px;

  ${media.phablet`
    padding : 0px 0px 160px 0px;
    `}
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;

  ${media.phablet`
    display : none;`}
`;

const OuterBodyContainer = styled.div`
  display: flex;

  ${media.phablet`  
  width: fit-content;
  height: fit-content;
  position: relative;
  `}
`;

const MoreContainer = styled.div`
  display: none;

  ${media.phablet`
    display: flex;
    position: absolute;

    width: 100%;
    justify-content: center;

    bottom: 0;
    gap: 8px;
    `}
`;

const Dot = styled.div.attrs<{ $color: string }>((props) => ({
  style: {
    backgroundColor: props.$color,
  },
}))`
  display: flex;
  width: 10px;
  height: 10px;
  border-radius: 50%;
`;

const BodyContainer = styled.div.attrs<{ $translateX: number }>((props) => ({
  style: {
    transform: `translateX(-${props.$translateX}px)`,
  },
}))`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 20px;

  ${media.phablet`
    justify-content : flex-start;

    padding : 40px;
    box-sizing : border-box;
    gap: 80px;   
    transition : transform 0.5s;
    `}
`;

const OuterInputContainer = styled.div`
  display: fklex;
  box-sizing: border-box;
  ${media.phablet`
    padding: 0px 40px;`}
`;

const InputContainer = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 95%;
  border-radius: 50px;
  border: 1.5px solid white;
  height: 72px;
  margin: 0 auto;
  align-items: center;
  padding-left: 30px;

  ${media.phablet`
    border-radius : 18px;
    width : 100%;
    height : 80px;
    padding : 16px 16px;
    `}
`;

const blinking = keyframes`
    0% {
        opacity: 0;
    }
    35% {
        opacity: 1;
    }
    70% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
    `;

const Carat = styled.div`
  width: 3px;
  height: 40px;
  background: black;
  animation: ${blinking} 1s linear infinite;

  ${media.tablet`
      display : none;`}
`;

const LeftArrowContainer = styled.div`
  display: flex;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);

  cursor: pointer;
`;

const RightArrowContainer = styled.div`
  display: flex;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: fit-content;
  height: fit-content;

  cursor: pointer;
`;

const ArrowContainer = styled.div`
  display: none;

  ${media.phablet`
      display : unset;
      scale: 0.25;`};
`;
