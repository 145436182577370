import { useEffect, useState } from "react";
import Spacer from "../Spacer";
import CompanyInfo from "./CompanyInfo";
import FooterTop from "./FooterTop";
import GridPattern from "../GridPattern";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import { media } from "../media";

export default function Footer() {
  const navigate = useNavigate();

  return (
    <Container>
      <TopContainer>
        <TopInnerContainer>
          <ColumnContainer>
            <LinkContainer onClick={() => navigate("/company")}>
              <div className="inter fs-14 fw-700">Company</div>
            </LinkContainer>
            <LinkContainer onClick={() => navigate("/company#our-culture")}>
              <div className="inter fs-14 fw-400">Our Culture</div>
            </LinkContainer>
            <LinkContainer onClick={() => navigate("/company#careers")}>
              <div className="inter fs-14 fw-400">Careers</div>
            </LinkContainer>
          </ColumnContainer>
          <ColumnContainer>
            <LinkContainer onClick={() => navigate("/#products")}>
              <div className="inter fs-14 fw-700">Products</div>
            </LinkContainer>
            <LinkContainer onClick={() => navigate("/#toonda")}>
              <div className="inter fs-14 fw-400">TOONDA</div>
            </LinkContainer>
            <LinkContainer onClick={() => navigate("/#reat")}>
              <div className="inter fs-14 fw-400">reat</div>
            </LinkContainer>
          </ColumnContainer>
          <ColumnContainer>
            <LinkContainer onClick={() => navigate("/contact")}>
              <div className="inter fs-14 fw-700">Contact</div>
            </LinkContainer>
            <LinkContainer onClick={() => navigate("/contact#business")}>
              <div className="inter fs-14 fw-400">Business</div>
            </LinkContainer>
          </ColumnContainer>
        </TopInnerContainer>
      </TopContainer>
      <BottomContainer>
        <LogoContainer>
          <img
            src={require("../../assets/images/textLogo.png")}
            style={{
              width: "100%",
            }}
            alt="logo"
          />
        </LogoContainer>
        <div className="inter fs-20 fw-700">©</div>
        <div
          className="inter fs-13 fw-500"
          style={{ color: "rgb(121, 119, 117)" }}
        >
          All Rights Reserved
        </div>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.div`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 350px;
  background: linear-gradient(0deg, #ffffff 77%, #f5f5f5 93%, #e1e1e1 100%);
  padding: 60px;

  ${media.phablet`
    padding : 40px;
    `}
`;

const TopContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

const TopInnerContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 100px;

  ${media.phablet`
    width : 100%;
    gap : 0px;
    justify-content : space-between;
    max-width : 480px;
  `}
`;

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BottomContainer = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  gap: 4px;
`;

const LinkContainer = styled.div`
  display: flex;
  cursor: pointer;
`;

const LogoContainer = styled.div`
  display: flex;
  width: 150px;
  height: auto;

  ${media.phablet`
    width : 100px;
    `}
`;
