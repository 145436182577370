import React, { useEffect } from "react";
import styled from "styled-components";
import PeopleCard from "./PeopleCard";
import { media } from "../../../components/media";

import { ReactComponent as RightSmallArrow } from "../../../assets/icons/page-arrow-right-small.svg";
import { ReactComponent as LeftSmallArrow } from "../../../assets/icons/page-arrow-left-small.svg";

interface Props {
  width: number;
}

export default function PeopleSection(props: Props) {
  const [colIndex, setColIndex] = React.useState(0);

  const [translateXValue, setTranslateXValue] = React.useState(0);

  useEffect(() => {
    setTranslateXValue(colIndex * (-props.width + 40));
  }, [colIndex, props.width]);

  useEffect(() => {
    setColIndex(0);
    if (props.width >= 800) {
      touchXRef.current = null;
    }
  }, [props.width]);

  const touchXRef = React.useRef<number | null>(null);

  function handleTouchStart(e: React.TouchEvent) {
    if (props.width >= 800) return;
    touchXRef.current = e.touches[0].clientX;
  }

  function handleTouchMove(e: React.TouchEvent) {
    if (touchXRef.current == null) return;

    const diff = touchXRef.current - e.touches[0].clientX;

    if (diff > 60) {
      setColIndex((prev) => {
        const newPage = prev + 1;
        if (newPage > 2) return 2;
        else return newPage;
      });

      touchXRef.current = null;
    } else if (diff < -60) {
      setColIndex((prev) => {
        const newPage = prev - 1;
        if (newPage < 0) return 0;
        else return newPage;
      });

      touchXRef.current = null;
    }
  }

  function handleTouchEnd() {
    touchXRef.current = null;
  }

  return (
    <Container
      $translateXValue={translateXValue}
      onTouchStart={handleTouchStart}
      onTouchMove={handleTouchMove}
      onTouchEnd={handleTouchEnd}
    >
      <ColContainer>
        <PeopleCard
          name="Coo"
          role="CREATIVE"
          bannerColor="#FFD700"
          content={[
            "Creative Thinking: 60%",
            "Planning: 20%",
            "Strategic Thinking: 20%",
            "Tennis: 100% (my favorite pastime)",
            '"Serving up creativity both on and off the court!"',
          ]}
          showLeftArrow={false}
          showRightArrow={colIndex === 0}
          onClickRight={
            colIndex === 0
              ? () => {
                  setColIndex(1);
                }
              : undefined
          }
        />
        <PeopleCard
          name="Bok"
          role="CREATIVE"
          bannerColor="#FFD700"
          content="I have a romantic spirit and a dynamic energy that drives me. While I often find myself busy with various pursuits, I feel that my true form has yet to fully emerge. Nonetheless, I embrace the joy in this journey."
          showLeftArrow={false}
          showRightArrow={colIndex === 0}
          onClickRight={
            colIndex === 0
              ? () => {
                  setColIndex(1);
                }
              : undefined
          }
        />
        <PeopleCard
          name="Han"
          role="RESEARCH"
          bannerColor="#00FFAE"
          content="A dopamine enthusiast who thrives on fun, but can turn gloomy without it. Passionate about sports and gaming, and perhaps enjoys a bit of cerebral challenge too!"
          showLeftArrow={false}
          showRightArrow={colIndex === 0}
          onClickRight={
            colIndex === 0
              ? () => {
                  setColIndex(1);
                }
              : undefined
          }
        />
      </ColContainer>
      <ColContainer>
        <PeopleCard
          name="Young"
          role="DEVELOPMENT"
          bannerColor="#C8FF00"
          content={
            "A person who knows as little as a tiny black dot in the vast world, embracing the joy of discovery and relishing in the many things still left to learn. One day, I hope to leave behind not just a dot, but a world of possibilities in the color black."
          }
          showLeftArrow={colIndex === 1}
          showRightArrow={colIndex === 1}
          onClickRight={
            colIndex === 1
              ? () => {
                  setColIndex(2);
                }
              : undefined
          }
          onClickLeft={
            colIndex === 1
              ? () => {
                  setColIndex(0);
                }
              : undefined
          }
        />
        <PeopleCard
          name="Ahyun"
          role="DESIGN"
          bannerColor="#B7A3FF"
          content={
            "I have a penchant for the absurd and the amusing. Though I may appear indifferent at times, I hold a heartfelt perspective on the world around me. My affection particularly extends to furry creatures, especially those that aren’t human—I can’t help but dote on them!"
          }
          showLeftArrow={colIndex === 1}
          showRightArrow={colIndex === 1}
          onClickRight={
            colIndex === 1
              ? () => {
                  setColIndex(2);
                }
              : undefined
          }
          onClickLeft={
            colIndex === 1
              ? () => {
                  setColIndex(0);
                }
              : undefined
          }
        />
        <PeopleCard
          name="Joon"
          role="CONPAPA"
          bannerColor="#000000"
          fontColor="white"
          content={
            "Our world is ever-changing, with every part shining in its own color. I hope we all recognize and bring our own colors to life. I want to contribute to the world’s evolution in my own vibrant way."
          }
          showLeftArrow={colIndex === 1}
          showRightArrow={colIndex === 1}
          onClickRight={
            colIndex === 1
              ? () => {
                  setColIndex(2);
                }
              : undefined
          }
          onClickLeft={
            colIndex === 1
              ? () => {
                  setColIndex(0);
                }
              : undefined
          }
        />
      </ColContainer>
      <ColContainer>
        <PeopleCard
          name="Annie"
          role="DEVELOPMENT"
          bannerColor="#C8FF00"
          content={
            "My English nickname, Annie, is inspired by Anne of Green Gables. I’m chatty, full of dreams, and eager to explore everything the world has to offer. With a natural inclination for all things new and exciting, I’m always in pursuit of joy."
          }
          showLeftArrow={colIndex === 2}
          showRightArrow={false}
          onClickLeft={
            colIndex === 2
              ? () => {
                  setColIndex(1);
                }
              : undefined
          }
        />
        <PeopleCard
          name="Ryan"
          role="R&D"
          bannerColor="#FF9AAB"
          content={
            "I enjoy solving problems, and my work revolves around this passion as well. One of my favorite hobbies is tackling escape rooms. I thrive on fun experiences; if something isn’t engaging, my interest fades quickly. The impact of enthusiasm on productivity is immense."
          }
          showLeftArrow={colIndex === 2}
          showRightArrow={false}
          onClickLeft={
            colIndex === 2
              ? () => {
                  setColIndex(1);
                }
              : undefined
          }
        />
        <EmptyCardContainer>
          <div
            className="inter fs-48 fw-800"
            style={{
              display: "flex",
              color: "white",
              fontStyle: "italic",
            }}
          >
            Who's <br /> Next?
          </div>
        </EmptyCardContainer>
      </ColContainer>
    </Container>
  );
}

const Container = styled.div.attrs<{ $translateXValue: number }>((props) => ({
  style: {
    transform: `translateX(${props.$translateXValue}px)`,
  },
}))`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  position: relative;
  transition: all 0.5s;

  ${media.phablet`
    padding : 0px 40px;
    gap : 40px;`}
`;

const ColContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 108px;

  ${media.phablet`
    width : 100%;
    min-width : 100%;
    max-width : 100%;`}
`;

const EmptyCardContainer = styled.div`
  display: flex;
  width: 340px;
  background: black;
  align-items: flex-end;
  padding: 32px;
  box-sizing: border-box;
  aspect-ratio: 1/1;

  ${media.desktop`
    width : 300px;`}

  ${media.tablet`
    width : 200px;`}

  ${media.phablet`
    min-width : 100%;
    max-width : 100%;
    width : 100%;
  `}
`;
