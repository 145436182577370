import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as AAA } from "../../../../assets/partner-logos/aaa.svg";
import { ReactComponent as COPUS } from "../../../../assets/partner-logos/copus.svg";
import { ReactComponent as COWEKO } from "../../../../assets/partner-logos/coweko.svg";
import { ReactComponent as DICIA } from "../../../../assets/partner-logos/dicia.svg";
import { ReactComponent as KENAZ } from "../../../../assets/partner-logos/kenaz.svg";
import { ReactComponent as REBEL } from "../../../../assets/partner-logos/rebel.svg";
import { ReactComponent as SBA } from "../../../../assets/partner-logos/sba.svg";
import { ReactComponent as SEJONG } from "../../../../assets/partner-logos/sejong.svg";
import { ReactComponent as YLAB } from "../../../../assets/partner-logos/ylab.svg";

import styled from "styled-components";
import LogoItem from "./LogoItem";

const LOGOS = [
  <AAA style={{ width: 120, height: 80 }} />,
  <COPUS style={{ width: 120, height: 80 }} />,
  <COWEKO style={{ width: 120, height: 80 }} />,
  <DICIA style={{ width: 120, height: 80 }} />,
  <KENAZ style={{ width: 120, height: 80 }} />,
  <REBEL style={{ width: 120, height: 80 }} />,
  <SBA style={{ width: 120, height: 80 }} />,
  <SEJONG style={{ width: 120, height: 80 }} />,
  <YLAB style={{ width: 120, height: 80 }} />,
];

export default function LogoAnimationComponent() {
  const [logos, setLogos] = useState<Array<React.ReactNode>>([...LOGOS]);

  const leftRef = useRef(0);
  const [currentLogoIndex, setcurrentLogoIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout>();

  // slide container to left
  useEffect(() => {
    intervalRef.current = setInterval(() => {
      const container = document.getElementById("partner-logo-container");
      if (container) {
        container.style.transform = `translateX(${leftRef.current}px)`;
        leftRef.current -= 1;
      }

      if (leftRef.current % 100 === 0) {
        // push the current logo to the end of the array
        setLogos((prev) => {
          const newLogos = [...prev];

          newLogos.push(newLogos[currentLogoIndex]);
          // remove first element
          return newLogos;
        });

        setcurrentLogoIndex((prev) => (prev + 1) % LOGOS.length);
      }
    }, 20);

    return () => {
      clearInterval(intervalRef.current!);
    };
  }, [currentLogoIndex]);

  return (
    <Container id="partner-logo-container">
      {logos.map((logo, index) => (
        <LogoItem key={index} logo={logo} />
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  gap: 80px;
  width: 100%;
`;
