import * as THREE from "three";
import { SlabopBase } from "./SlabopBase"; // SlabopBase가 정의된 파일에서 import합니다.
import { Slab } from "../Slab";

export class Gradient extends SlabopBase {
  public grid: any; // grid 타입을 적절하게 정의해야 합니다.

  constructor(fs: string, grid: any) {
    super(
      fs,
      {
        p: { type: "t" },
        w: { type: "t" },
        gridSize: { type: "v2" },
        gridScale: { type: "f" },
      },
      grid
    );

    this.grid = grid;
  }

  public compute(
    renderer: THREE.WebGLRenderer,
    p: Slab,
    w: Slab,
    output: Slab
  ): void {
    this.uniforms.p.value = p.read.texture;
    this.uniforms.w.value = w.read.texture;
    this.uniforms.gridSize.value = this.grid.size;
    this.uniforms.gridScale.value = this.grid.scale;

    // output.write를 renderTarget으로 설정하여 렌더링
    renderer.setRenderTarget(output.write);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null); // 기본 render target으로 되돌리기

    output.swap();
  }
}
