import React, { useEffect } from "react";
import styled, { css, keyframes } from "styled-components";
import { ReactComponent as MissionStatement } from "../../../assets/images/mission-statement.svg";
import { media } from "../../../components/media";
import HashtagContainer from "./HashTagContainer";

interface Props {
  width: number;
}
export default function MissionSection(props: Props) {
  const missionRef = React.useRef<HTMLDivElement>(null);
  const [missionVisible, setMissionVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setMissionVisible(true);
          } else {
          }
        });
      },
      { threshold: 1 }
    );

    observer.observe(missionRef.current!);

    return () => observer.disconnect();
  }, []);

  return (
    <Container>
      <MissionMainContainer>
        <ImageContainer>
          {/* <img
          src={require("../../../assets/images/mission-background.png")}
          alt="mission"
          style={{
            width: "100%",
          }}
        /> */}
          <InnerContainer>
            <MissionTextContainer>
              <div className="inter fs-14 fw-400">Mission</div>
            </MissionTextContainer>
            <div style={{ height: 30 }} />
            <MissionContainer $visible={missionVisible} ref={missionRef}>
              <div
                className="shrik fs-64 fw-400 phablet:fs-48 phablet:lh-1.2"
                style={{
                  textAlign: "center",
                }}
              >
                Reinvent Your Creative Journey
              </div>
            </MissionContainer>
            <div
              className="inter fs-36 fw-500 phablet:fs-20"
              style={{
                whiteSpace: "nowrap",
              }}
            >
              Create Boldly, Shape Bravely
            </div>
            <HorizDivider />
            <div
              className="inter fs-24 fw-400 phablet:fs-18 lh-1.7"
              style={{ textAlign: "center" }}
            >
              We empower creators to elevate ideas beyond imagination. <br />
              From the first spark to the final masterpiece, our solutions guide
              you on an extraordinary journey—where your stories become
              unforgettable realities.
            </div>
          </InnerContainer>
        </ImageContainer>
      </MissionMainContainer>
      <HashtagContainer width={props.width} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const MissionMainContainer = styled.div`
  position: relative;
  flex-direction: column;
  display: flex;
  width: 100%;
  background-image: url(${require("../../../assets/images/mission-background.png")});
  background-color: black;
  background-size: cover;
  margin-top: 320px;
  padding: 160px 100px;
  box-sizing: border-box;

  ${media.phablet`
    margin-top : 160px;
    padding : 80px 40px 160px 40px;
    `}
`;

const ImageContainer = styled.div`
  display: flex;
  width: 100%;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
`;

const smoke = keyframes`
  from {
    opacity : 0;
    filter : blur(20px);
  }
  to {
    opacity : 1;
    filter : blur(0px);
  }
`;

const MissionTextContainer = styled.div`
  width: 100%;
`;

const MissionContainer = styled.div<{ $visible: boolean }>`
  display: flex;
  opacity: 0;

  animation: ${(props) =>
    props.$visible
      ? css`
          ${smoke} 1.5s ease-in-out forwards
        `
      : "none"};

  ${media.phablet`
    padding : 0px 40px;
    text-align : center;
    `}
`;

const HorizDivider = styled.div`
  height: 100px;

  ${media.phablet`
    height : 60px;`}
`;
