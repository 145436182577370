import React from "react";

import styled from "styled-components";
import { media } from "../../../components/media";

interface Props {
  width: number;
}

export default function HashtagContainer(props: Props) {
  const HASHTAGS = ["#CREATIVE", "#PIONEER", "#HUMOROUS", "#FORWARD_LOOKING"];
  return (
    <TagContainer>
      {HASHTAGS.map((tag, index) => (
        <div
          key={index}
          className="inter fs-20 fw-300 phone:fs-12 phablet:fs-16"
          style={{ color: "white" }}
        >
          {tag}
        </div>
      ))}
    </TagContainer>
  );
}

const TagContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  align-items: center;
  justify-content: space-around;
`;
