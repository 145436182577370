import React from "react";
import styled from "styled-components";
import { ReactComponent as Toonda } from "../../../assets/icons/toondaWhiteLogo.svg";
import { ReactComponent as Reat } from "../../../assets/icons/reatWhiteLogo.svg";
import { media } from "../../../components/media";

export default function ExploreSection() {
  return (
    <Container>
      <Item1>
        <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
          <div className="inter fs-60 fw-700 tablet:fs-36 phablet:fs-32">
            <LineBreakSpan>Goodbye,</LineBreakSpan>
            creative burnout. <br />
            <LineBreakSpan>Hello, </LineBreakSpan>
            <LineBreakSpan style={{ whiteSpace: "nowrap" }}>
              a joy-filled journey.
            </LineBreakSpan>
          </div>
          <div className="inter fs-36 fw-700 tablet:fs-24 phablet:fs-18">
            with TOONDA and reat&nbsp;&nbsp;
          </div>
        </div>
      </Item1>
      <Item2>
        <div className="inter fs-18 fw-300 lh-1.7 tablet:fs-14 phablet:fs-14">
          Explore CONPAPA’s groundbreaking solutions crafted to enhance every
          facet of the creative journey. Our innovative visual tools empower
          creators to collaborate seamlessly and communicate with clarity,
          tackling challenges at every stage of the creative process and
          establishing a new benchmark for excellence in creative endeavors. Our
          mission is to inspire every creator to boldly redefine their unique
          path, enabling them to achieve unprecedented heights in their work.
        </div>
      </Item2>
      {/* <BodyContainer>
        <TopContainer>
          <div className="inter fs-24 fw-700">
            Explore CONPAPA’s groundbreaking solutions crafted to enhance every
            facet of the creative journey.
          </div>
        </TopContainer>
        <BottomContainer>
          <div className="inter fs-18 fw-300" style={{ lineHeight: "30px" }}>
            Our innovative visual tools empower creators to collaborate
            seamlessly and communicate with clarity, tackling challenges at
            every stage of the creative process and establishing a new benchmark
            for excellence in creative endeavors. Our mission is to inspire
            every creator to boldly redefine their unique path, enabling them to
            achieve unprecedented heights in their work.
          </div>
        </BottomContainer>
      </BodyContainer>
      <LogoContainer>
        <Toonda />
        <Reat />
      </LogoContainer> */}
    </Container>
  );
}

const Container = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr;
  padding: 320px 0px;

  ${media.phablet`
    grid-template-columns: 1fr;
    padding : 160px 40px;`}
`;

const GridItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Item1 = styled(GridItem)`
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  grid-column: 1;
  grid-row: 1;
  padding: 16px 0px;

  text-align: right;

  ${media.phablet`
    justify-content : flex-start;
    width : 100%;
    text-align : left;
    `}
`;

const Item2 = styled(GridItem)`
  display: flex;
  grid-column: 2;
  grid-row: 2;
  box-sizing: border-box;
  width: 450px;

  ${media.tablet`
    width : 300px;
    `}

  ${media.phablet`
    grid-column: 1;
    grid-row: 2;

    padding : 16px 0px;
    justify-content : flex-start;
    width : 100%;
    text-align : left;
    `}
`;

const LineBreakSpan = styled.span`
  display: block;

  ${media.phablet`
    display : inline;
    `}

  ${media.phone`
      display : block;
      `}
`;

// const Container = styled.div`
//   display: flex;
//   flex-direction: column;
//   box-sizing: border-box;
//   padding: 200px 30px;
//   background-image: url(${require("../../../assets/images/explore-background.png")});
//   background-size: cover;
//   gap: 48px;
// `;

// const LeftContainer = styled.div`
//   display: flex;
//   flex: 1;
// `;
// const RightContainer = styled.div`
//   display: flex;
//   flex: 1;
// `;

// const BodyContainer = styled.div`
//   display: flex;
//   flex-direction: column;
//   box-sizing: border-box;
//   gap: 24px;
// `;

// const TopContainer = styled.div`
//   display: flex;
//   box-sizing: border-box;
//   background: white;
//   width: fit-content;
//   align-items: center;
//   padding: 10px 20px;
// `;

// const BottomContainer = styled.div`
//   box-sizing: border-box;
//   display: flex;
//   background: white;
//   padding: 20px;
//   max-width: 840px;
// `;

// const LogoContainer = styled.div`
//   display: flex;
//   align-items: center;
//   gap: 36px;
// `;
