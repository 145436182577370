import React from "react";

import styled from "styled-components";
import EmailSectionItem from "./EmailSectionItem";
import SubmitFormSection from "./SubmitFormSection";
import { media } from "../../../components/media";

export default function EmailSection() {
  return (
    <Container>
      <EmailSectionItem title="Inquiry and Sales" email="contact@conpapa.co" />
      <Spacer />
      <EmailSectionItem
        title="Marketing and Partnership"
        email="marketing@conpapa.co"
      />
      <Spacer />
      <EmailSectionItem title="Tech Support" email="support@conpapa.co" />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-around;

  ${media.phablet`
    flex-direction: column;
    gap : 48px;
    align-items: center;`}
`;

const Spacer = styled.div`
  display: flex;
  width: 1px;
  height: 48px;
  background-color: black;

  ${media.phablet`
  width : 48px;
  height : 1px;`}
`;
