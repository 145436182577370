import styled from "styled-components";
import * as COLORS from "../../assets/colors";
import AuthInput from "../../components/AuthInput";
import { useEffect, useState } from "react";
import PrimaryButtonComponent from "../../components/PrimaryButtonComponent";
import { P13_500, P16_500, Pretendard } from "../../assets/fonts";
import { ReactComponent as BottomLogo } from "../../assets/icons/ic_toondaFeedLogo.svg";
import { ReactComponent as ToondaLogo } from "../../assets/icons/toondaMainLogo.svg";
import { ReactComponent as FeedLogo } from "../../assets/icons/feedMainLogo.svg";

import axios from "axios";

export default function OpenAlarm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [selectedCategories, setSelectedCategories] = useState<
    Record<string, boolean>
  >({ TOONDA: false, FEED: false });
  const [buttonDisabled, setButtonDisabled] = useState(false);

  const [isToondaSelected, setToondaSelected] = useState(false);
  const [isFeedSelected, setFeedSelected] = useState(false);

  const [isSettingAlarm, setSettingAlarm] = useState(false);

  useEffect(() => {
    let categorySelected = false;

    if (isToondaSelected === true || isFeedSelected === true) {
      categorySelected = true;
    }

    if (name === "" || email === "" || categorySelected === false) {
      setButtonDisabled(true);
    } else {
      setButtonDisabled(false);
    }
  }, [name, email, isToondaSelected, isFeedSelected]);

  function handleToondaSelection() {
    const newRecord = selectedCategories;
    const selected = !isToondaSelected;

    if (selected === true) {
      newRecord["TOONDA"] = true;
    } else {
      newRecord["TOONDA"] = false;
    }

    setSelectedCategories(newRecord);
    setToondaSelected(selected);
  }

  function handleFeedSelection() {
    const newRecord = selectedCategories;
    const selected = !isFeedSelected;

    if (selected === true) {
      newRecord["FEED"] = true;
    } else {
      newRecord["FEED"] = false;
    }

    setSelectedCategories(newRecord);
    setFeedSelected(selected);
  }

  async function setAlarm() {
    try {
      if (isSettingAlarm === true) return;

      setSettingAlarm(true);

      const result = await axios.post(
        "https://vwxzh1e47b.execute-api.ap-northeast-2.amazonaws.com/default/email-notification",
        {
          email: email,
          name: name,
          toonda: selectedCategories["TOONDA"] ?? false,
          feed: selectedCategories["FEED"] ?? false,
        }
      );

      if (result.data.statusCode !== 200)
        throw new Error("Setting alarm failed");

      alert("알람 신청이 완료되었습니다.");
      setName("");
      setEmail("");
      setToondaSelected(false);
      setFeedSelected(false);
      setSelectedCategories({});
      setAgree(false);
    } catch (err) {
      alert("에러가 발생했습니다 : " + err);
    } finally {
      setSettingAlarm(false);
    }
  }

  const [agree, setAgree] = useState(false);

  const [isPC, setPC] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // 윈도우의 크기가 변경될 때마다 이벤트 리스너를 등록합니다.
    window.addEventListener("resize", handleResize);

    // 컴포넌트가 unmount될 때 이벤트 리스너를 제거합니다.
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (windowWidth > 640) {
      setPC(true);
    } else {
      setPC(false);
    }
  }, [windowWidth]);

  return (
    <Container>
      <TopContainer>
        <TopTitleContainer>
          <P16_500 color={COLORS.Black}>오픈 알람</P16_500>
        </TopTitleContainer>
        <TopMainContainer>
          <AuthInput
            text="이름"
            placeholder="이름을 입력해주세요"
            type="text"
            value={name}
            onChange={(event) => setName(event.target.value)}
          />
          <AuthInput
            text="이메일"
            placeholder="이메일을 입력해주세요"
            type="email"
            value={email}
            onChange={(event) => setEmail(event.target.value)}
          />
          <CategoryContainer>
            <P13_500 color={COLORS.Black}>
              툰다와 피드중 어떤 제품에 관심이 있으세요?
            </P13_500>
            <CategoryButtonContainer>
              <CategoryButtonFrame
                isSelected={isToondaSelected}
                onClick={handleToondaSelection}
              >
                <ToondaLogo />
              </CategoryButtonFrame>
              <CategoryButtonFrame
                isSelected={isFeedSelected}
                onClick={handleFeedSelection}
              >
                <FeedLogo />
              </CategoryButtonFrame>
            </CategoryButtonContainer>
          </CategoryContainer>
          <AgreeContainer>
            <P13_500 color={COLORS.Gray500}>
              개인정보 수집에 동의하십니까?
            </P13_500>
            <AgreeCheckBox
              type="checkbox"
              checked={agree}
              onChange={({ target: { checked } }) => setAgree(checked)}
            />
          </AgreeContainer>
          <PrimaryButtonComponent
            width={isPC ? 378 : 298}
            height={30}
            buttonColor={COLORS.ToonBlue}
            shadowColor={COLORS.ToonBlue100}
            borderColor={COLORS.NearBlack}
            buttonDisabledColor={COLORS.ToonBlue100}
            disabled={!agree || buttonDisabled}
            onClick={setAlarm}
          >
            <P13_500
              color={!agree || buttonDisabled ? COLORS.Gray300 : COLORS.Ivory}
            >
              알림 신청하기
            </P13_500>
          </PrimaryButtonComponent>
        </TopMainContainer>
      </TopContainer>
      <BottomLogo />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 124.5px;
  width: 100vw;
  height: 100vh;

  background: ${COLORS.Background};
`;

const TopContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 40px;
`;

const TopTitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  align-self: stretch;
`;

const TopMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 30px;
`;

const CategoryContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 12px;
`;

const CategoryButtonContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 12px;
`;

interface CategoryButtonFrameProps {
  isSelected: boolean;
}

const CategoryButtonFrame = styled.div<CategoryButtonFrameProps>`
  display: flex;
  padding: 0px 18px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  height: 44px;

  border-radius: 2px;
  border: 1px solid ${COLORS.OffWhite};
  background: ${(props) =>
    props.isSelected ? COLORS.OffWhite : COLORS.Gray100};

  &:hover {
    cursor: pointer;
    box-shadow: ${(props) =>
      props.isSelected ? "" : "2px 2px 0px 0px rgba(0, 0, 0, 0.25)"};
  }
`;

const AgreeContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const AgreeCheckBox = styled.input`
  display: flex;
  width: 20px;
  height: 20px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;

  border-radius: 2px;
  border: 1px solid ${COLORS.Gray300};
  background: ${COLORS.Gray100};

  accent-color: ${COLORS.Orange};
`;
