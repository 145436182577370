import * as THREE from "three";

export class SlabopBase {
  public camera: THREE.OrthographicCamera;
  public scene: THREE.Scene;
  public uniforms: any;

  constructor(
    fs: string,
    uniforms: any,
    grid: { size: { x: number; y: number } }
  ) {
    const geometry = new THREE.PlaneGeometry(
      (2 * (grid.size.x - 2)) / grid.size.x,
      (2 * (grid.size.y - 2)) / grid.size.y
    );

    const material = new THREE.ShaderMaterial({
      uniforms: uniforms,
      fragmentShader: fs,
      depthWrite: false,
      depthTest: false,
      blending: THREE.NoBlending,
    });

    this.uniforms = uniforms;

    const quad = new THREE.Mesh(geometry, material);

    this.camera = new THREE.OrthographicCamera(-1, 1, 1, -1, 0, 1);
    this.scene = new THREE.Scene();
    this.scene.add(quad);
  }
}
