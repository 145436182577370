// src/styles/media.ts
import { css, CSSObject } from "styled-components";

type DeviceType = "tablet" | "phone" | "phablet" | "desktop";

const sizes: Record<DeviceType, number> = {
  desktop: 1439,
  tablet: 1049,
  phablet: 799,
  phone: 599,
};

const media = Object.entries(sizes).reduce((acc, [key, value]) => {
  return {
    ...acc,
    [key]: (
      first: CSSObject | TemplateStringsArray,
      ...interpolations: any[]
    ) => css`
      @media (max-width: ${value}px) {
        ${css(first, ...interpolations)}
      }
    `,
  };
}, {}) as Record<DeviceType, any>;

export { media };
