import * as THREE from "three";
import { Slab } from "../Slab";
import { SlabopBase } from "./SlabopBase"; // SlabopBase가 정의된 파일에서 import합니다.

export class Splat extends SlabopBase {
  public grid: any; // grid 타입을 적절하게 정의해야 합니다.
  public radius: number;

  constructor(fs: string, grid: any, radius?: number) {
    super(
      fs,
      {
        read: { type: "t" },
        gridSize: { type: "v2" },
        color: { type: "v3" },
        point: { type: "v2" },
        radius: { type: "f" },
      },
      grid
    );

    this.grid = grid;
    this.radius = radius ?? 0.1;
  }

  public compute(
    renderer: THREE.WebGLRenderer,
    input: Slab,
    color: THREE.Vector3,
    point: THREE.Vector2,
    output: Slab
  ): void {
    this.uniforms.gridSize.value = this.grid.size;
    this.uniforms.read.value = input.read.texture;
    this.uniforms.color.value = color; // THREE.Color를 배열로 변환
    this.uniforms.point.value = point;
    this.uniforms.radius.value = this.radius;

    // output.write를 renderTarget으로 설정하여 렌더링
    renderer.setRenderTarget(output.write);
    renderer.render(this.scene, this.camera);
    renderer.setRenderTarget(null); // 기본 render target으로 되돌리기

    output.swap();
  }
}
