import React from "react";
import styled from "styled-components";
import { media } from "../../../components/media";

interface Props {
  title: string;
  email: string;
}
export default function EmailSectionItem(props: Props) {
  return (
    <Container>
      <TopContainer>
        <div className="inter fs-20 fw-300 phablet:fs-16 tablet:fs-18">
          {props.title}
        </div>
      </TopContainer>
      <BottomContainer>
        <div className="inter fs-20 fw-600 phablet:fs-16 tablet:fs-18">
          {props.email}
        </div>
      </BottomContainer>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  ${media.phablet`
    align-items: center;
  `}
`;

const TopContainer = styled.div`
  display: flex;
`;

const BottomContainer = styled.div`
  display: flex;
`;
