import React, { useState, useEffect, useRef } from "react";
import Header from "../../components/Header";
import Body from "./Body";
import Footer from "../../components/Footer";
import Contact from "../Contact";
import MobileHeader from "../../components/Header/MobileHeader";
import { useLocation, useNavigate } from "react-router-dom";
import { moveToId } from "../../utils";
import GridPattern from "../../components/GridPattern";
import TopBar from "../../components/TopBar";
import ThreeScene from "./ThreeScene";
import ThreeScene2 from "./ThreeScene2";
import { ConpapaLogo } from "../../assets/images/conpapaLogo";
import styled, { css, keyframes } from "styled-components";
import { media } from "../../components/media";
import FormingSection from "./FormingSection";
import MissionSection from "./MissionSection";
import ImpactSection from "./ImpactSection";
import PartnerSection from "./PartnerSection";
import ReinventSection from "./ReinventSection";
import ExploreSection from "./ExploreSection";
import ProductSection from "./ProductSection";
import ReactOutSection from "./ReactOutSection";

export default function Home() {
  const logoRef = useRef<HTMLDivElement | null>(null);
  const event1Ref = useRef<HTMLDivElement | null>(null);
  const event2Ref = useRef<HTMLDivElement | null>(null);

  const [eventOneHappened, setEventOneHappened] = useState(false);
  const [eventTwoHappened, setEventTwoHappened] = useState(false);

  // const [disableThreeScene, setDisableThreeScene] = useState(false);

  const [logoScrollRatio, setLogoScrollRatio] = React.useState(0);

  // useEffect(() => {
  //   // hook wheel event
  //   const handleWheel = (e: WheelEvent) => {
  //     if (eventOneHappened === true && disableThreeScene === false) {
  //       e.preventDefault();
  //     }
  //   };

  //   window.addEventListener("wheel", handleWheel, { passive: false });

  //   return () => {
  //     window.removeEventListener("wheel", handleWheel);
  //   };
  // }, [eventOneHappened, disableThreeScene]);

  useEffect(() => {
    // when threeSceneRef is out of viewport, set disableThreeScene to true

    const firstEventObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (entry.isIntersecting) {
          // setEventOneHappened(true);
          setEventOneHappened(false);
        } else {
          // if already on the top of viewport, also set eventOneHappened to true
          if (entry.boundingClientRect.top < 0) setEventOneHappened(true);
        }
      }, // set root margin for checking when this element hits the top of the viewport
      {
        threshold: 0.0,
      }
    );

    const secondEventObserver = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];

        if (entry.isIntersecting) {
          setEventTwoHappened(true);

          // turn on the spotlight sequentially
        } else {
          // if already on the top of viewport, also set eventTwoHappened to true
          if (entry.boundingClientRect.top < 0) setEventTwoHappened(true);
        }
      },
      {
        // set root margin for checking when this element hits the top of the viewport
        rootMargin: `0px 0px -70% 0px`,
        threshold: 0.0,
      }
    );

    if (event1Ref.current) {
      firstEventObserver.observe(event1Ref.current);
    }

    if (event2Ref.current) {
      secondEventObserver.observe(event2Ref.current);
    }

    return () => {
      // firstEventObserver.disconnect();
      secondEventObserver.disconnect();
    };
  }, []);

  useEffect(() => {
    // if (disableThreeScene === false || eventTwoHappened === false) return;
    if (eventTwoHappened === false) return;

    setTimeout(() => {
      // wait till disableThreeScene is true
      setSpotlightFlag((prev) => {
        prev[0] = true;
        return [...prev];
      });
    }, 2000);

    setTimeout(() => {
      setSpotlightFlag((prev) => {
        prev[1] = true;
        return [...prev];
      });
    }, 2100);

    setTimeout(
      () => {
        setSpotlightFlag((prev) => {
          prev[2] = true;
          return [...prev];
        });
      },

      2200
    );

    setTimeout(
      () => {
        setSpotlightFlag((prev) => {
          prev[3] = true;
          return [...prev];
        });
      },

      2300
    );

    setTimeout(
      () => {
        setSpotlightFlag((prev) => {
          prev[4] = true;
          return [...prev];
        });
      },

      2400
    );

    setTimeout(
      () => {
        setSpotlightFlag((prev) => {
          prev[5] = true;
          return [...prev];
        });
      },

      2500
    );

    setTimeout(
      () => {
        setSpotlightFlag((prev) => {
          prev[6] = true;
          return [...prev];
        });
      },

      2600
    );

    setTimeout(
      () => {
        setSpotlightFlag((prev) => {
          prev[7] = true;
          return [...prev];
        });
      },

      2700
    );
    // }, [disableThreeScene, eventTwoHappened]);
  }, [eventTwoHappened]);

  // const [logoShown, setLogoShown] = React.useState(false);

  // useEffect(() => {
  //   //
  //   const observer = new IntersectionObserver(
  //     (entries) => {
  //       const entry = entries[0];

  //       if (entry.isIntersecting) {
  //         console.log("intersecting");

  //         // change body overflow to hidden
  //         document.body.style.overflow = "hidden";

  //         setLogoShown(true);
  //       } else {
  //         setLogoShown(false);
  //       }
  //     },
  //     { threshold: 1.0 }
  //   );

  //   console.log(logoRef.current);
  //   if (logoRef.current) observer.observe(logoRef.current);

  //   return () => {
  //     observer.disconnect();
  //   };
  // }, []);

  // useEffect(() => {
  //   // scrollevent hook
  //   const handleWheel = (e: WheelEvent) => {
  //     // if body overflow is not hidden, return
  //     if (document.body.style.overflow !== "hidden") return;

  //     setLogoScrollRatio((prev) => {
  //       const cur = prev + e.deltaY / 1000;
  //       console.log(cur);
  //       if (cur < 0) {
  //         return 0;
  //       }

  //       if (cur >= 1) {
  //         // change body overflow to visible
  //         document.body.style.overflow = "auto";
  //         return 1;
  //       }

  //       return cur;
  //     });
  //   };

  //   window.addEventListener("wheel", handleWheel, { passive: false });

  //   return () => {
  //     window.removeEventListener("wheel", handleWheel);
  //   };
  // }, []);

  const [isContactVisible, setContactVisible] = useState<boolean>(false);
  // 현재 창의 너비를 저장할 상태
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);

    function initSearchParams(url: string) {
      searchParams.set(url, "false");
      const newURL = `${window.location.pathname}?${searchParams.toString()}`;
      window.history.replaceState(null, "", newURL);

      navigate("/");
    }

    if (searchParams.get("moveToUserOutput") === "true") {
      moveToId("#userOutput", false);
      initSearchParams("moveToUserOutput");
    } else if (searchParams.get("moveToMission") === "true") {
      moveToId("#mission", false);
      initSearchParams("moveToMission");
    } else if (searchParams.get("moveToKeyValue") === "true") {
      moveToId("#keyValue", false);
      initSearchParams("moveToKeyValue");
    }
  }, [location.search]);

  // 화면 크기가 변경될 때마다 창의 너비 업데이트
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    // 이벤트 리스너 등록
    window.addEventListener("resize", handleResize);

    // 컴포넌트 언마운트 시 이벤트 리스너 제거
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // 부드럽게 스크롤
    });
  };

  const toggleBodyVisibility = () => {
    setContactVisible(false);
  };

  const toggleContactVisibility = () => {
    setContactVisible(!isContactVisible);
  };

  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (e: MouseEvent) => {
      setMousePos({ x: e.clientX, y: e.clientY });
    };

    window.addEventListener("mousemove", handleMouseMove);
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  const [playRandomSpotlight, setPlayRandomSpotlight] = useState(true);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  useEffect(() => {
    // randomly change the spotlight in every 1 second
    // if (eventTwoHappened === false || disableThreeScene === false) return;
    if (eventTwoHappened === false) return;

    if (intervalRef.current) clearInterval(intervalRef.current);

    intervalRef.current = setInterval(() => {
      setSpotlightFlag((prev) => {
        const idx = Math.floor(Math.random() * 8);
        prev[idx] = !prev[idx];
        return [...prev];
      });
    }, 3000);
    // }, [eventTwoHappened, disableThreeScene]);
  }, [eventTwoHappened]);

  function updateSpotlightFlag(idx: number) {
    setSpotlightFlag((prev) => {
      prev[idx] = !prev[idx];
      return [...prev];
    });
  }

  const [spotlightFlag, setSpotlightFlag] = useState<Array<boolean>>([
    false,
    false,
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  return (
    <Container>
      <InnerContainer $bgColor="white" $maxWidth={1440}>
        <FormlessContainer></FormlessContainer>
        {windowWidth > 1049 ? (
          <ThreeSceneOuterContainer ref={event1Ref}>
            {eventOneHappened === false ? (
              <ThreeSceneContainer $visible={true}>
                <ThreeScene2 key={windowWidth} />
              </ThreeSceneContainer>
            ) : (
              <div style={{ height: 800 }} />
            )}
          </ThreeSceneOuterContainer>
        ) : (
          <MobileSmokeContainer>
            <video
              autoPlay
              loop
              muted
              playsInline
              src="https://difvv72vh86tt.cloudfront.net/public/common/homepage/smoke_video.mp4#t=2.5"
              style={{}}
            />
            <div
              style={{
                position: "absolute",
              }}
            >
              <div className="inter fs-36 phablet:fs-24 fw-300">
                A{" "}
                <b>
                  <i>
                    <span>Formless</span>
                  </i>
                </b>{" "}
                Idea
              </div>
              <div className="inter fs-36 fw-300 phablet:fs-24">
                goes through <span className="shrik">Forming</span>
              </div>
            </div>
          </MobileSmokeContainer>
        )}
        <DrawDescribeContainer>
          <div className="inter fs-11 fw-400">
            * Freely express what you imagine in your mind
          </div>
        </DrawDescribeContainer>
        <CenterContainer ref={event2Ref}>
          <EmergyContainer className="inter fs-36 fw-300 phablet:fs-24">
            and ultimately emerges as a{" "}
            <i>
              <b>Formed</b>
            </i>
            &nbsp;creation.
          </EmergyContainer>
          <FadeInDiv $visible={eventTwoHappened} style={{ width: "100%" }}>
            <LogoContainer $visible={eventTwoHappened} ref={logoRef}>
              <div style={{ display: "flex", width: "100%" }}>
                <ConpapaLogo
                  lights={{
                    light1: spotlightFlag[0],
                    light2: spotlightFlag[1],
                    light3: spotlightFlag[2],
                    light4: spotlightFlag[3],
                    light5: spotlightFlag[4],
                    light6: spotlightFlag[5],
                    light7: spotlightFlag[6],
                    light8: spotlightFlag[7],
                  }}
                />
              </div>
              <FadeInDiv
                $visible={eventTwoHappened}
                style={{
                  display: "flex",
                  width: "90%",
                  alignContent: "center",
                  justifyContent: "space-between",
                }}
              >
                <SpotlightContainer onClick={() => updateSpotlightFlag(0)}>
                  <div
                    className="inter fs-14 fw-500 phablet:fs-9"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Graphic Design
                  </div>
                </SpotlightContainer>
                <SpotlightContainer onClick={() => updateSpotlightFlag(1)}>
                  <div
                    className="inter fs-14 fw-500 phablet:fs-9"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Animation
                  </div>
                </SpotlightContainer>
                <SpotlightContainer onClick={() => updateSpotlightFlag(2)}>
                  <div
                    className="inter fs-14 fw-500 phablet:fs-9"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Webtoon
                  </div>
                </SpotlightContainer>
                <SpotlightContainer onClick={() => updateSpotlightFlag(3)}>
                  <div
                    className="inter fs-14 fw-500 phablet:fs-9"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Movie
                  </div>
                </SpotlightContainer>
                {windowWidth < 800 ? null : (
                  <>
                    <SpotlightContainer onClick={() => updateSpotlightFlag(4)}>
                      <div
                        className="inter fs-14 fw-500 phablet:fs-9"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Photographic
                      </div>
                    </SpotlightContainer>
                    <SpotlightContainer onClick={() => updateSpotlightFlag(5)}>
                      <div
                        className="inter fs-14 fw-500 phablet:fs-9"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Manga
                      </div>
                    </SpotlightContainer>
                    <SpotlightContainer onClick={() => updateSpotlightFlag(6)}>
                      <div
                        className="inter fs-14 fw-500 phablet:fs-9"
                        style={{
                          whiteSpace: "nowrap",
                        }}
                      >
                        Web Design
                      </div>
                    </SpotlightContainer>
                  </>
                )}
                <SpotlightContainer onClick={() => updateSpotlightFlag(7)}>
                  <div
                    className="inter fs-14 fw-500 phablet:fs-9"
                    style={{
                      whiteSpace: "nowrap",
                    }}
                  >
                    Commercial
                  </div>
                </SpotlightContainer>
              </FadeInDiv>
            </LogoContainer>
          </FadeInDiv>
        </CenterContainer>
        <FormingSection width={windowWidth} />
      </InnerContainer>
      <InnerContainer $bgColor="black">
        <InnerContainer $bgColor="black" $maxWidth={1440}>
          <MissionSection width={windowWidth} />
          <TogetherContainer>
            <div
              className="inter fs-36 fw-700 phablet:fs-28"
              style={{ textAlign: "center", color: "white" }}
            >
              <LineBreakSpan>Together,</LineBreakSpan> we’ll unlock the horizons
              of human creativity.
            </div>
          </TogetherContainer>
          <ImpactSection windowWidth={windowWidth} />
        </InnerContainer>
      </InnerContainer>
      <InnerContainer $bgColor="white" $maxWidth={1440}>
        <ExploreSection />
        <ProductSection width={windowWidth} />
        <PartnerSection />
        <ReactOutSection />
      </InnerContainer>
    </Container>
  );
}

const fadeout = keyframes`
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
`;

const fadein = keyframes`
  from {
    opacity: 0;
  }
    
  to {
    opacity: 1;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
`;

const InnerContainer = styled.div.attrs<{
  $bgColor: string;
  $maxWidth?: number;
}>((props) => ({
  style: {
    backgroundColor: props.$bgColor,
    maxWidth: props.$maxWidth,
    margin: props.$maxWidth == null ? undefined : "0 auto",
  },
}))`
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 0px 80px;

  width: 100%;

  ${media.phablet`
    padding : 0px 0px;
    `}
`;

const FormlessContainer = styled.div`
  display: flex;
  width: 100%;
  height: 50px;
  justify-content: center;
  align-items: flex-end;
`;

const MobileSmokeContainer = styled.div`
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: column;
  box-sizing: border-box;
  height: 800px;

  align-items: center;
  justify-content: center;
`;

const DrawDescribeContainer = styled.div`
  display: flex;
  width: 100%;
  margin-top: 10px;
  justify-content: flex-end;
  box-sizing: border-box;

  transform: translateX(40px);

  ${media.tablet`
    display : none;`}
`;

const ThreeSceneOuterContainer = styled.div`
  display: flex;
  position: relative;
  width: 100%;

  align-items: center;
  justify-content: center;

  padding-top: 100px;

  z-index: 0;
`;

const ThreeSceneContainer = styled.div<{ $visible: boolean }>`
  opacity: 1;
  animation: ${(props) =>
    props.$visible === false
      ? css`
          ${fadeout} 2s forwards
        `
      : `none`};

  z-index: 10;
`;

const TextContainer = styled.div`
  font-family: "Noto Sans KR", sans-serif;
  font-size: 36px;
  font-weight: 400;
  line-height: 1.45;
  letter-spacing: -0.04em;
`;

const FadeInDiv = styled.div<{ $visible: boolean }>`
  opacity: 0;

  animation: ${(props) =>
    props.$visible === true
      ? css`
          ${fadein} 2s forwards
        `
      : `none`};
`;

const CenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 700px;
  align-items: center;
  padding: 50px 0px 160px 0px;

  ${media.phablet`
    padding : 50px 0px 0px 0px;
    height : fit-content;
    `}
`;

const EmergyContainer = styled.div`
  text-align: center;
  padding: 300px 50px 120px 50px;

  ${media.phablet`
    padding : 160px 50px 80px 50px;
    `}
`;

const LogoContainer = styled(FadeInDiv)`
  display: flex;
  max-width: fit-content;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  ${media.phablet`
    padding : 0px 10px;`}
`;

const SpotlightContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

const TogetherContainer = styled.div`
  display: flex;
  box-sizing: border-box;
  justify-content: center;
  padding: 480px 0px;

  ${media.phablet`
    padding : 160px 60px;`}
`;

const LineBreakSpan = styled.span`
  ${media.phablet`
  display : block;
  `}
`;
